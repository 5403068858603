import { useSetMobileDevice } from '@openware/opendax-web-sdk'
import { CrossIcon } from 'assets/images/CrossIcon'
import Image from 'next/image'
import React, { useEffect } from 'react'

interface DisclaimerProps {
  title: React.ReactNode
  imgSrc?: string
  description: React.ReactNode
  primaryButtonLabel?: React.ReactNode
  secondaryButtonLabel?: React.ReactNode
  isShown: boolean
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
}

export const Disclaimer: React.FC<DisclaimerProps> = ({
  title,
  imgSrc,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  isShown,
  onPrimaryClick,
  onSecondaryClick,
}: DisclaimerProps) => {
  const [isDisclaimerShown, setIsDisclaimerShown] = React.useState<boolean>(isShown)

  const isMobile = useSetMobileDevice()

  useEffect(() => {
    setIsDisclaimerShown(isShown)
  }, [isShown])

  const handlePrimaryClick = React.useCallback(() => {
    onPrimaryClick && onPrimaryClick()
    setIsDisclaimerShown(false)
  }, [onPrimaryClick])

  const handleSecondaryClick = React.useCallback(() => {
    onSecondaryClick && onSecondaryClick()
    setIsDisclaimerShown(false)
  }, [onSecondaryClick])

  const renderDesktopModal = React.useMemo(() => {
    return (
      <div className="fixed right-9 bottom-10 z-40 w-[344px] flex flex-col p-6 drop-shadow-xl bg-body-background-color rounded-sm">
        <div className="relative">
          <h3 className="mb-[18px] text-lg leading-6 mr-5 font-inter font-medium text-text-color-100">{title}</h3>
          {imgSrc && (
            <div className="relative mb-[18px] w-[296px] h-[167px]">
              <Image src={imgSrc} alt="disclaimer" width={296} height={167} layout="fill" objectFit="contain" />
            </div>
          )}
          <span className="text-sm font-metro-medium text-neutral-control-layer-color-60">{description}</span>
          <div className="gap-3 flex flex-col mt-6">
            {primaryButtonLabel && (
              <button
                className="py-[7px] border rounded-sm drop-shadow-sm text-sm font-metro-semibold duration-200 text-primary-cta-layer-color-60 bg-primary-cta-color-60 border-primary-cta-layer-color-60 hover:bg-primary-cta-color-80"
                onClick={handlePrimaryClick}
              >
                {primaryButtonLabel}
              </button>
            )}
            {secondaryButtonLabel && (
              <button
                className="py-[7px] border rounded-sm drop-shadow-sm text-sm font-metro-semibold duration-200 text-neutral-control-layer-color-70 bg-neutral-control-color-0 border-neutral-control-color-70 hover:bg-neutral-control-color-20 hover:border-neutral-control-color-90"
                onClick={handleSecondaryClick}
              >
                {secondaryButtonLabel}
              </button>
            )}
          </div>
          <div
            className="absolute -top-1 -right-1 cursor-pointer"
            onClick={() => {
              setIsDisclaimerShown(false)
            }}
          >
            <CrossIcon fill="var(--neutral-control-color-0)" stroke="var(--neutral-control-layer-color-40)" />
          </div>
        </div>
      </div>
    )
  }, [title, imgSrc, description, primaryButtonLabel, secondaryButtonLabel, handlePrimaryClick, handleSecondaryClick])

  const renderMobileModal = React.useMemo(() => {
    return (
      <div className="bg-black bg-opacity-10 h-full w-full fixed top-0 backdrop-blur-sm z-20">
        <div className="fixed bottom-16 z-50 w-full flex flex-col p-6 drop-shadow-xl bg-body-background-color rounded-t-lg">
          <div className="relative">
            <h3 className="mb-[18px] text-lg leading-6 mr-5 font-inter font-medium text-text-color-100">{title}</h3>
            {imgSrc && (
              <div className="mb-[18px]">
                <Image src={imgSrc} alt="disclaimer" width={700} height={395} />
              </div>
            )}
            <span className="text-sm font-metro-medium text-neutral-control-layer-color-60">{description}</span>
            <div className="gap-3 flex flex-col mt-6">
              {primaryButtonLabel && (
                <button
                  className="py-[7px] border rounded-sm drop-shadow-sm text-sm font-metro-semibold duration-200 text-primary-cta-layer-color-60 bg-primary-cta-color-60 border-primary-cta-layer-color-60 hover:bg-primary-cta-color-80"
                  onClick={handlePrimaryClick}
                >
                  {primaryButtonLabel}
                </button>
              )}
              {secondaryButtonLabel && (
                <button
                  className="py-[7px] border rounded-sm drop-shadow-sm text-sm font-metro-semibold duration-200 text-neutral-control-layer-color-70 bg-neutral-control-color-0 border-neutral-control-color-70 hover:bg-neutral-control-color-20 hover:border-neutral-control-color-90"
                  onClick={handleSecondaryClick}
                >
                  {secondaryButtonLabel}
                </button>
              )}
            </div>
            <div
              className="absolute -top-1 -right-1 cursor-pointer"
              onClick={() => {
                setIsDisclaimerShown(false)
              }}
            >
              <CrossIcon fill="var(--neutral-control-color-0)" stroke="var(--neutral-control-layer-color-40)" />
            </div>
          </div>
        </div>
      </div>
    )
  }, [])

  if (!isDisclaimerShown) {
    return <></>
  }

  return isMobile ? renderMobileModal : renderDesktopModal
}
