import { getSupabasePublicClient } from 'libs/SupabaseConnector'

export const deleteArticle = async (id: number, table: string) => {
  const supabase = getSupabasePublicClient()
  const { error } = await supabase.from(table).delete().match({ id })

  if (error) {
    return error
  }
}
