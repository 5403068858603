import { FC } from 'react'

interface SidebarIconsProps {
  name: string
  className?: string
  secondaryColor?: string
  primaryColor: string
}

export const SidebarIcons: FC<SidebarIconsProps> = ({
  name,
  className,
  primaryColor,
  secondaryColor,
}: SidebarIconsProps) => {
  switch (name) {
    case 'trading':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.61 8.413v9.167H4.38V13.26l3.962-3.86 3.941 2.037 1.742.906 1.314-1.456 2.271-2.495v.02Zm2.557-6.58-6.712 7.364-5.572-2.903-6.05 5.898v7.975h18.334V1.833Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'orders':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.61 4.38v6.834l-6.396 6.396H4.38V4.38h13.24-.01Zm2.557-2.547H1.833v18.334h10.43l7.904-7.904V1.833Z"
            fill={primaryColor}
          />
          <path d="M12.192 20.156H9.646V9.635h10.51v2.546h-7.964v7.975Z" fill={primaryColor} />
        </svg>
      )
    case 'deposit':
      return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.0757 11.3969C21.8764 11.3269 21.6887 11.2568 21.5011 11.2101C21.4548 11.1996 21.4067 11.1996 21.3604 11.2101C20.9261 11.0813 20.4765 11.0106 20.0235 11C18.5595 11.0031 17.1564 11.5834 16.1212 12.6138C15.086 13.6443 14.5031 15.0411 14.5 16.4984C14.5004 16.9508 14.5555 17.4015 14.6642 17.8409C14.6642 17.8409 14.6642 17.9342 14.6642 17.9926C14.7161 18.1827 14.7788 18.3698 14.8518 18.553C15.1926 19.3967 15.7384 20.1431 16.4402 20.7254C17.142 21.3076 17.9779 21.7075 18.8731 21.8892C19.7682 22.0708 20.6947 22.0287 21.5694 21.7664C22.4442 21.5041 23.2399 21.03 23.8854 20.3864C24.5309 19.7428 25.0059 18.9499 25.2679 18.0787C25.53 17.2075 25.5708 16.2852 25.3868 15.3944C25.2029 14.5036 24.7998 13.6722 24.2138 12.9745C23.6277 12.2769 22.877 11.7348 22.0288 11.3969H22.0757ZM20.0235 19.0316L18.6162 17.6191L17.4435 16.4517L20.0235 13.9885L20.9734 14.9224L21.1493 15.0858L21.8998 15.833L22.5683 16.4867L20.0235 19.0316Z"
            fill={primaryColor}
          />
          <path
            d="M16.2998 24.98L18.2798 23L19.9998 24.72L21.7298 23L23.7098 24.98L19.9998 28.68L16.2998 24.98Z"
            fill={secondaryColor}
          />
        </svg>
      )
    case 'withdraw':
      return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.14 24.1396H11.85V26.9496H28.14V24.1396Z" fill={secondaryColor} />
          <path
            d="M24.38 17.4996L22.4 19.4796L20 17.0196L17.53 19.4796L15.55 17.4996L20 13.0596L24.38 17.4996Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'settings':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.533 6.495a6.37 6.37 0 0 0-3.275-1.75v-.032a.912.912 0 0 0 .149-.5v-.95a.917.917 0 0 0-.917-.916h-.949a.917.917 0 0 0-.916.917v.948c0 .182.053.352.144.494v.043a6.38 6.38 0 0 0-3.59 2.096.914.914 0 0 0-.254-.27l-.778-.545a.917.917 0 0 0-1.276.225l-.545.778a.917.917 0 0 0 .226 1.276l.777.544a.913.913 0 0 0 .587.164l.032.018a6.38 6.38 0 0 0 .181 4.43l-.04.047a.913.913 0 0 0-.403.157l-.777.544a.917.917 0 0 0-.225 1.277l.544.777a.917.917 0 0 0 1.277.225l.777-.544a.913.913 0 0 0 .285-.323h.05a6.38 6.38 0 0 0 3.077 1.621l.02.054a.913.913 0 0 0-.09.396v.949c0 .506.411.917.917.917h.95c.505 0 .916-.41.916-.917v-.949a.912.912 0 0 0-.093-.402l.018-.047a6.382 6.382 0 0 0 2.908-1.462h.048c.05.06.11.116.177.163l.777.544c.415.29.987.19 1.277-.225l.544-.777a.917.917 0 0 0-.225-1.277l-.777-.544a.927.927 0 0 0-.153-.087l-.036-.047a6.38 6.38 0 0 0 .247-4.394l.026-.034a.913.913 0 0 0 .558-.165l.777-.544a.917.917 0 0 0 .225-1.277l-.544-.777a.917.917 0 0 0-1.277-.225l-.777.544a.919.919 0 0 0-.194.184h-.054a6.392 6.392 0 0 0-.326-.352Zm-2.378 1.324a3.832 3.832 0 0 0-2.129-.646 3.842 3.842 0 0 0-3.832 3.832 3.832 3.832 0 1 0 5.96-3.186Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'history':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.61 14.555v3.055H4.38v-3.055h13.24-.01Zm2.557-2.536H1.833v8.148h18.334v-8.148ZM17.987 6.926H4.003v2.546h13.984V6.926ZM15.807 1.833H6.172V4.38h9.636V1.833Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'balances':
      return (
        <svg width="20" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.2217 11.7332C12.6996 11.9612 12.1359 12.0784 11.5661 12.0776C11.1895 12.0781 10.8149 12.0219 10.455 11.911C10.4997 12.1378 10.5221 12.3686 10.5217 12.5998C10.5199 13.3277 10.3035 14.0388 9.89947 14.6443C9.59651 15.0818 9.20115 15.4474 8.74134 15.7152C8.28153 15.9831 7.7685 16.1467 7.2385 16.1944C6.70851 16.2422 6.17449 16.1729 5.67421 15.9916C5.17393 15.8102 4.71958 15.5212 4.3433 15.1449C3.96702 14.7686 3.67799 14.3143 3.49663 13.814C3.31528 13.3137 3.24603 12.7797 3.29378 12.2497C3.34154 11.7197 3.50512 11.2067 3.77298 10.7469C4.04084 10.2871 4.40643 9.89169 4.84391 9.58873C5.44936 9.18475 6.16051 8.96831 6.88836 8.96651C7.11594 8.96614 7.34299 8.98847 7.56614 9.03317C7.46249 8.67192 7.41012 8.29789 7.41058 7.92206C7.40976 7.35234 7.52704 6.78863 7.75503 6.26651C7.46836 6.22136 7.17856 6.19907 6.88836 6.19984C5.71098 6.19613 4.55531 6.51671 3.5481 7.12643C2.54088 7.73615 1.72102 8.61146 1.17842 9.65636C0.635826 10.7013 0.391448 11.8754 0.472089 13.05C0.55273 14.2247 0.955277 15.3544 1.63558 16.3153C2.31589 17.2763 3.24768 18.0313 4.32877 18.4977C5.40986 18.964 6.59849 19.1237 7.76432 18.9591C8.93015 18.7945 10.0281 18.3121 10.9379 17.5647C11.8476 16.8173 12.534 15.8338 12.9217 14.7221C13.169 14.0421 13.2932 13.3234 13.2884 12.5998C13.2891 12.3096 13.2668 12.0198 13.2217 11.7332Z"
            fill={primaryColor}
          />
          <path
            d="M11.5667 0.977234C9.96679 0.978187 8.41624 1.53157 7.17722 2.54384C5.93821 3.55611 5.08673 4.96516 4.76674 6.53279C4.67733 6.97908 4.63267 7.43318 4.63341 7.88835C4.62959 8.4508 4.70058 9.01127 4.84452 9.55501C5.14643 10.7766 5.77561 11.8928 6.66442 12.7836C7.55323 13.6743 8.66803 14.306 9.88896 14.6106C10.4365 14.7545 11.0006 14.8254 11.5667 14.8217C12.0219 14.823 12.4761 14.7783 12.9223 14.6883C14.5875 14.3304 16.0627 13.3721 17.0666 11.9961C18.0704 10.62 18.5326 8.92267 18.365 7.22768C18.1974 5.53269 17.4118 3.95869 16.158 2.80589C14.9041 1.65309 13.2698 1.00215 11.5667 0.977234ZM13.2217 11.7332C12.6996 11.9612 12.1359 12.0784 11.5661 12.0776C11.1895 12.0781 10.8149 12.0219 10.455 11.911C9.76579 11.722 9.13794 11.3571 8.63161 10.8527C8.12529 10.3483 7.75779 9.72168 7.56614 9.03317C7.46249 8.67192 7.41012 8.29789 7.41058 7.92206C7.40745 7.1691 7.60902 6.42898 7.99272 5.78111C8.37642 5.13324 8.92851 4.60145 9.5903 4.24228C10.2521 3.88311 10.9988 3.70999 11.7511 3.74131C12.5035 3.77263 13.2332 4.00723 13.8629 4.42017C14.4925 4.83311 14.9985 5.40894 15.327 6.08647C15.6555 6.76399 15.7943 7.51787 15.7286 8.26797C15.6629 9.01806 15.3951 9.73634 14.9538 10.3464C14.5125 10.9565 13.9135 11.436 13.2217 11.7332Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'home':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m11 5.103 6.62 5.357v7.13H4.38v-7.13L11 5.103Zm0-3.27L1.833 9.238v10.929h18.334V9.237L11 1.834Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'assets':
      return (
        <svg width="22" height="22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m14.605 10.986-3.589-3.589-3.588 3.589 3.588 3.588 3.589-3.588Z" fill={primaryColor} />
          <path
            d="M11.005 4.504a6.481 6.481 0 1 1 .04 12.962 6.481 6.481 0 0 1-.04-12.962Zm0-2.548a9.03 9.03 0 1 0 0 18.058 9.03 9.03 0 0 0 0-18.058Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'news':
      return (
        <svg
          width="22"
          height="22"
          className={className}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.641 1.71875V8.66909H20.1668V11.2358H18.641V18.0257L10.9387 15.4262H5.71838L3.88735 18.928L1.61285 17.7387L3.2424 14.6222C3.14881 14.5461 3.05377 14.4629 2.95873 14.372C2.18172 13.629 1.46677 12.4298 1.46677 10.6508C1.46677 8.87186 2.18172 7.67267 2.95873 6.92966C3.33442 6.57042 3.71007 6.33053 3.99679 6.17821C4.14098 6.10161 4.26551 6.04565 4.36043 6.00675C4.40799 5.98726 4.44845 5.97192 4.4806 5.9603C4.49669 5.95449 4.51073 5.9496 4.52259 5.94558L4.53872 5.94019L4.54553 5.93796L4.54862 5.93697L4.55008 5.9365C4.55079 5.93627 4.55149 5.93605 4.94123 7.15877L4.55149 5.93605L4.65961 5.90159L10.8052 5.08451L18.641 1.71875ZM5.27118 8.40953C5.25083 8.41918 5.22723 8.43092 5.20094 8.44488C5.07682 8.51082 4.90469 8.62014 4.73259 8.78471C4.41404 9.08932 4.03343 9.63615 4.03343 10.6508C4.03343 11.6655 4.41404 12.2123 4.73259 12.517C4.90469 12.6815 5.07682 12.7908 5.20094 12.8568C5.2027 12.8577 5.20445 12.8586 5.20619 12.8596H11.3601L16.0743 14.4506V5.61466L11.4936 7.58223L5.27118 8.40953Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'classic':
      return (
        <svg
          width="22"
          height="22"
          className={className}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.254 11.352H14.4747V3.52541H6.64811V0.746094H17.254V11.352Z" fill={primaryColor} />
          <path
            d="M14.8828 1.15278L5.54378 10.4918L7.50905 12.4571L16.8481 3.11806L14.8828 1.15278Z"
            fill={primaryColor}
          />
          <path d="M3.04601 18.0004V14.9543H-0.00012207V18.0004H3.04601Z" fill={primaryColor} />
        </svg>
      )
    case 'articles':
      return (
        <svg
          width="22"
          height="22"
          className={className}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.38982 17.6204L4.38982 10.7861L10.7861 4.38984L17.6204 4.38984L17.6204 17.6204L4.37963 17.6204L4.38982 17.6204ZM1.83334 20.1667L20.1667 20.1667L20.1667 1.83335L9.73704 1.83336L1.83334 9.73706L1.83334 20.1667Z"
            fill={primaryColor}
          />
          <path
            d="M9.80823 1.84388L12.3545 1.84388L12.3545 12.3652L1.84342 12.3652L1.84342 9.81888L9.80823 9.81888L9.80823 1.84388Z"
            fill={primaryColor}
          />
          <path d="M14.2083 13.75L14.2083 15.838L7.79167 15.838L7.79167 13.75L14.2083 13.75Z" fill={primaryColor} />
          <path
            d="M15.5528 7.99539L15.5528 10.0834L11.4583 10.0834L11.4583 7.99539L15.5528 7.99539Z"
            fill={primaryColor}
          />
        </svg>
      )
    case 'wallet':
      return (
        <svg
          className={className}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0091 7.75015V16.0187H3.98132V7.75015H16.0184H16.0091ZM18.3332 5.43533H1.6665V18.3335H18.3332V5.43533Z"
            fill={primaryColor}
          />
          <path
            d="M14.7696 11.882L12.9761 10.0886L11.1827 11.882L12.9761 13.6755L14.7696 11.882Z"
            fill={primaryColor}
          />
          <path
            d="M15.2128 6.00008H12.898V3.98156H3.98132V6.00008H1.6665V1.66675H15.2128V6.00008Z"
            fill={primaryColor}
          />
        </svg>
      )
    default:
      return null
  }
}

SidebarIcons.defaultProps = {
  className: 'flex-shrink-0 h-5 w-5',
}
