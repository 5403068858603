import { translate } from 'helpers'
import { Category } from 'types'

export const getHeaderNavigation = (categories: Category[]) => {
  if (categories == null) {
    return {
      navigations: [],
    }
  }
  const headerNavigation = [
    { name: translate('page.tab.header.all'), href: '/' },
    ...categories.map(category => {
      return {
        name: category.name,
        href: `/${category.category}`,
      }
    }),
  ]

  return {
    navigations: headerNavigation,
  }
}
