import { useAppSelector } from '@openware/opendax-web-sdk'
import { Disclaimer } from 'components/Disclaimer'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export const TestnetDisclaimer: FC = () => {
  const [hideModal, setHideModal] = useState<boolean>(true)

  const user = useAppSelector(state => state.user.user)
  const userLoading = useAppSelector(state => state.user.userLoading)
  const networkId = useAppSelector(state => state.chain.chain?.networkId)

  const router = useRouter()
  const paths = [
    '/trading/[marketID]',
    '/orders/all',
    '/orders/open',
    '/history/deposits',
    '/history/withdrawals',
    '/history/trades',
    '/balances',
    '/settings',
  ]

  useEffect(() => {
    const accepted = localStorage.getItem('TESTNET_ACKNOWLEDGED') === 'true'

    setHideModal(accepted)
  }, [])

  const handleAccepted = () => {
    localStorage.setItem('TESTNET_ACKNOWLEDGED', 'true')
    setHideModal(true)
  }

  const showModalPath = paths.includes(router.pathname)

  return !userLoading && networkId ? (
    <Disclaimer
      title={<FormattedMessage id="modal.disclaimer.testnet.title" />}
      description={<FormattedMessage id="modal.disclaimer.testnet.description" />}
      isShown={!!user?.id && networkId !== 1 && !hideModal && showModalPath}
      imgSrc="/images/testnet-disclaimer.png"
      primaryButtonLabel={<FormattedMessage id="modal.disclaimer.testnet.button.ok" />}
      onPrimaryClick={() => handleAccepted()}
      onSecondaryClick={() => router.push('/help')}
    />
  ) : (
    <></>
  )
}
