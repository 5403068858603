import { getSupabasePublicClient } from 'libs/SupabaseConnector'
import { NextApiRequest, NextApiResponse } from 'next'

// 1 day
const refreshPeriod = 24 * 60 * 60 * 1000

export const extractCached = async (
  req: NextApiRequest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  res: NextApiResponse,
) => {
  const cached = JSON.parse(req.cookies['cached'] || 'null')
  const supabase = getSupabasePublicClient()

  if (!cached || cached.expiredAt > Date.now()) {
    const { data: categories } = await supabase.from('categories').select()

    const { data: markets } = await supabase
      .from('markets')
      .select('id, symbol, base_unit, quote_unit, amount_precision, price_precision')
      .limit(10)

    const cookies = {
      expiredAt: Date.now() + refreshPeriod,
      data: {
        categories,
        markets,
      },
    }

    // res.setHeader('set-cookie', [
    //     `cookies=${JSON.stringify(cookies ? cookies : '')}; path=/`,
    // ]);
    return cookies.data
  }
  return cached.data
}
