import { MobileModal, Modal, useSetMobileDevice } from '@openware/opendax-web-sdk'
import { AlertIcon } from 'assets/images/alertIcon'
import { InfoIcon } from 'assets/images/infoIcon'
import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export enum ConfirmationModalType {
  Publish = 'publish',
  Draft = 'draft',
  Delete = 'delete',
  Pin = 'pin',
  Unpin = 'unpin',
  Replace = 'replace',
}

export enum ArticleType {
  Article = 'article',
  Blog = 'blog',
}

interface ConfirmationModalProps {
  type: ConfirmationModalType
  showModal?: boolean
  setShowModal?: any
  onConfirm?: () => any
  articleType?: ArticleType
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  type,
  showModal,
  setShowModal,
  onConfirm,
  articleType = ArticleType.Article,
}: ConfirmationModalProps) => {
  const isMobile = useSetMobileDevice()

  const defaultConfirmButtonColor =
    'w-full inline-flex justify-center rounded-sm shadow-sm px-4 py-2 text-sm font-medium sm:ml-3 sm:w-auto sm:text-sm'

  const infoProps = {
    modalIcon: (
      <div className="mt-1 mr-4">
        <InfoIcon fill="var(--primary-cta-color-10)" stroke="var(--primary-cta-color-90)" />
      </div>
    ),
    classNameConfirmButton: classNames(
      defaultConfirmButtonColor,
      'border border-text-color-100 bg-primary-cta-color-60 hover:bg-primary-cta-color-80 text-primary-cta-layer-color-60',
    ),
  }

  const alertProps = {
    modalIcon: (
      <div className="mt-1 mr-4">
        <AlertIcon fill="var(--system-red-10)" stroke="var(--system-red-60)" />
      </div>
    ),
    classNameConfirmButton: classNames(
      defaultConfirmButtonColor,
      'bg-system-red-60 hover:bg-system-red-80 text-system-red-10',
    ),
  }

  const modalProps = React.useMemo(() => {
    const labelProps = {
      label: (
        <FormattedMessage id={`modal.confirmation${articleType === ArticleType.Blog ? '.blog' : ''}.title.${type}`} />
      ),
      confirmButtonLabel: <FormattedMessage id={`modal.confirmation.button.${type}`} />,
      modalbody: (
        <div className="mt-2 pb-4 text-text-color-60 font-normal text-sm">
          <FormattedMessage id={`modal.confirmation${articleType === ArticleType.Blog ? '.blog' : ''}.body.${type}`} />
        </div>
      ),
    }

    switch (type) {
      case ConfirmationModalType.Publish:
      case ConfirmationModalType.Draft:
      case ConfirmationModalType.Pin:
      case ConfirmationModalType.Unpin:
      case ConfirmationModalType.Replace:
        return {
          ...labelProps,
          ...infoProps,
        }
      case ConfirmationModalType.Delete:
        return {
          ...labelProps,
          ...alertProps,
        }
    }
  }, [type, articleType])

  const renderDesktopModal = () => {
    return (
      <Modal
        {...modalProps}
        closeButtonLabel={<FormattedMessage id="page.articles.pinned.cancel" />}
        onConfirm={onConfirm}
        onClose={() => setShowModal(false)}
        hideCloseIcon
        modalClassName="inline-block align-bottom rounded-sm pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-body-background-color"
        classNameBackground="fixed inset-0 transition-opacity backdrop-blur-sm bg-black/10"
        classNameFormLabel="text-text-color-100 font-medium text-lg"
        classNameCloseButton="mt-3 w-full inline-flex justify-center rounded-sm border border-neutral-control-color-70 shadow-sm px-4 py-2 bg-neutral-control-color-0 hover:bg-neutral-control-color-10 text-sm font-medium text-neutral-control-layer-color-80 sm:mt-0 sm:w-auto sm:text-sm"
        bodyClassName="flex px-4 sm:px-6"
        footerClassName="px-4 sm:px-6 py-3 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse bg-neutral-control-color-20"
      />
    )
  }

  const renderMobileModal = () => {
    const title = (
      <div className="flex items-center mb-4">
        {modalProps.modalIcon}
        {modalProps.label}
      </div>
    )

    const footer = (
      <div>
        <button onClick={onConfirm} type="button" className={modalProps.classNameConfirmButton}>
          {modalProps.confirmButtonLabel}
        </button>
        <button
          onClick={() => setShowModal(false)}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-sm border border-neutral-control-color-70 shadow-sm px-4 py-2 bg-neutral-control-color-0 text-sm font-medium text-neutral-control-layer-color-80 sm:mt-0 sm:w-auto sm:text-sm"
        >
          <FormattedMessage id="page.articles.pinned.cancel" />
        </button>
      </div>
    )

    return (
      <MobileModal
        open
        setOpen={() => setShowModal(false)}
        title={title}
        body={modalProps.modalbody}
        footer={footer}
        modalClassName="w-full inline-block align-bottom bg-body-background-color rounded-t-lg sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full p-6 pb-20"
      />
    )
  }

  const renderModal = () => {
    return isMobile ? renderMobileModal() : renderDesktopModal()
  }

  return showModal ? renderModal() : <></>
}
