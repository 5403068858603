export const Logo = (
  <svg width="137" height="36" viewBox="0 0 137 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.93921H6.75947L11.8863 22.1209L16.8127 6.93921H23.4576L14.8078 29.7684C13.0893 34.3003 11.2276 35.9997 7.41823 35.9997C5.09824 35.9997 3.37973 35.4049 1.74715 34.4702L3.89529 29.9101C4.7259 30.4199 5.78564 30.7881 6.61626 30.7881C7.70465 30.7881 8.30612 30.4482 8.85032 29.3436L0 6.93921Z"
      fill="#090909"
    />
    <path
      d="M102.166 7.98732H108.639L112.277 21.4979L116.516 7.90234H122.015L126.311 21.5546L130.006 7.98732H136.364L129.347 30.4483H123.59L119.237 16.7678L114.826 30.4483H109.126L102.166 7.98732Z"
      fill="#090909"
    />
    <path d="M90.9664 16.5415H85.5244V21.9231H90.9664V16.5415Z" fill="#FCD000" />
    <path
      d="M93.8289 21.3848C93.1129 23.679 91.1366 25.3785 88.301 25.3785C85.5801 25.3785 83.5179 23.679 82.7445 21.3848H76.2715C77.2453 26.7947 82.0285 30.845 88.2438 30.845C94.459 30.845 99.2422 26.7663 100.273 21.3848H93.8289Z"
      fill="#090909"
    />
    <path
      d="M88.2174 13.0857C90.9384 13.0857 93.0006 14.7851 93.7739 17.0794H100.247C99.2731 11.6695 94.4899 7.61914 88.2747 7.61914C82.0594 7.61914 77.2762 11.6978 76.2451 17.0794H82.6609C83.4056 14.7851 85.3532 13.0857 88.2174 13.0857Z"
      fill="#090909"
    />
    <path
      d="M46.9142 21.3561C46.9429 20.7613 47.0001 20.1948 47.0001 19.685C47.0001 13.3687 43.5631 7.59058 36.0303 7.59058C29.4714 7.59058 24.8887 12.8305 24.8887 19.2035V19.2884C24.8887 26.1145 29.901 30.8163 36.6605 30.8163C40.7276 30.8163 43.7636 29.2302 45.8831 26.6527L42.217 23.4521C40.4412 25.0666 38.8945 25.7463 36.7464 25.7463C33.8822 25.7463 31.8773 24.2452 31.2472 21.3844C31.0753 20.7046 30.9607 20.0532 30.9607 19.2884C30.9607 18.552 30.9894 18.1555 31.1612 17.3907C31.6768 14.5583 33.3953 12.6889 36.0303 12.6889C38.7227 12.6889 40.4125 14.615 40.7849 17.419H36.0017V21.3561H46.9142Z"
      fill="#090909"
    />
    <path
      d="M58.3725 23.594V0H52.1572V25.4634C52.1572 28.2108 54.3913 30.4201 57.1695 30.4201H58.3725H61.4945V25.2085H60.0051C59.0885 25.2085 58.3725 24.472 58.3725 23.594Z"
      fill="#090909"
    />
    <path
      d="M71.0034 23.8772V0H64.7881V25.7466C64.7881 28.4941 67.0221 30.7033 69.8004 30.7033H71.0034H74.1253V25.4917H72.6359C71.748 25.4917 71.0034 24.7553 71.0034 23.8772Z"
      fill="#090909"
    />
  </svg>
)

export const betaLogo = (
  <svg width="128" height="45" viewBox="0 0 128 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M119.402 15.103C119.461 15.009 119.59 14.9386 119.708 14.9386H124.51H124.651C124.757 14.9386 125.003 15.056 124.933 15.15L110.067 35.7458C109.973 35.8749 109.821 35.9571 109.656 35.9571H105.136C104.889 35.9571 104.607 35.6753 104.748 35.464L119.402 15.103Z"
      fill="#FCD000"
    />
    <path
      d="M41.3283 29.4167C41.1757 29.311 40.976 29.3345 40.8586 29.4754C39.2852 31.3072 37.5591 31.7886 35.7743 31.7886C31.6997 31.7886 29.2691 29.0057 29.2691 25.6005C29.2691 22.1952 31.9933 19.4123 35.3281 19.4123C38.0992 19.4123 40.1424 21.0445 41.023 23.3929C41.0817 23.5573 40.9643 23.7452 40.7764 23.7452H34.4826C34.283 23.7452 34.1303 23.8978 34.1303 24.0975V27.1035C34.1303 27.3031 34.283 27.4557 34.4826 27.4557H41.1404H46.0604C46.2953 27.4557 46.4831 27.2913 46.5184 27.0565C46.6006 26.5516 46.6358 26.0936 46.6358 25.6005C46.6358 19.5415 41.5632 14.6567 35.375 14.6567C29.1869 14.6567 24.1143 19.5415 24.1143 25.6005C24.1143 31.6594 29.1869 36.5442 35.375 36.5442C39.4143 36.5442 42.573 34.8885 44.6866 32.2113C44.7805 32.0939 44.757 31.9295 44.6396 31.8356L41.3283 29.4167Z"
      fill="#090909"
    />
    <path
      d="M56.1001 28.7356V5.9675C56.1001 5.76788 55.9475 5.61523 55.7479 5.61523H50.8161C50.6165 5.61523 50.4639 5.76788 50.4639 5.9675V28.1837C50.4639 33.6556 52.2957 36.1097 57.8262 36.1097H58.4603C58.7186 36.1097 58.93 35.8984 58.93 35.64V31.6829C58.93 31.4833 58.7773 31.3306 58.5777 31.3306H58.4955C57.063 31.3306 56.1001 30.6966 56.1001 28.7356Z"
      fill="#090909"
    />
    <path
      d="M67.8423 28.7356V5.9675C67.8423 5.76788 67.6897 5.61523 67.49 5.61523H62.5583C62.3587 5.61523 62.2061 5.76788 62.2061 5.9675V28.1837C62.2061 33.6556 64.0378 36.1097 69.5684 36.1097H70.2025C70.4608 36.1097 70.6722 35.8984 70.6722 35.64V31.6829C70.6722 31.4833 70.5195 31.3306 70.3199 31.3306H70.2377C68.8052 31.3306 67.8423 30.6966 67.8423 28.7356Z"
      fill="#090909"
    />
    <path
      d="M3.42468 44.2001V39.9376C3.42468 39.738 3.57733 39.5854 3.77695 39.5854H5.72615C6.95909 39.5854 8.07459 38.4111 8.63822 37.2369C8.63822 37.2252 8.64996 37.2134 8.64996 37.2017L8.87306 36.5207C8.967 36.2506 8.95526 35.957 8.83784 35.6987L0.0311902 15.4199C-0.0744896 15.1851 0.101642 14.9268 0.35997 14.9268H5.42086C5.60873 14.9268 5.77312 15.0324 5.84358 15.2086L11.8908 28.6064C11.973 28.7943 12.2431 28.7943 12.3253 28.6064L17.9615 15.2321C18.032 15.0559 18.2081 14.9502 18.396 14.9502H23.5156C23.7739 14.9502 23.9383 15.2086 23.8444 15.4434L13.5582 39.3623C12.2548 42.5327 9.2723 44.5758 5.7379 44.5758H3.78869C3.58908 44.5523 3.42468 44.3997 3.42468 44.2001Z"
      fill="#090909"
    />
    <path
      d="M96.5048 14.9502H101.542C101.73 14.9502 101.906 15.0676 101.977 15.2438L110.02 35.4873C110.114 35.7221 109.938 35.9687 109.691 35.9687H104.9C104.713 35.9687 104.536 35.8513 104.466 35.6752L96.1878 15.4316C96.0821 15.1968 96.2465 14.9502 96.5048 14.9502Z"
      fill="#090909"
    />
    <path
      d="M108.059 14.9502H113.097C113.284 14.9502 113.461 15.0676 113.531 15.2438L121.574 35.4873C121.668 35.7221 121.492 35.9687 121.246 35.9687H116.455C116.267 35.9687 116.091 35.8513 116.02 35.6752L107.742 15.4316C107.648 15.1968 107.813 14.9502 108.059 14.9502Z"
      fill="#090909"
    />
    <path
      d="M126.999 19.6471L125.156 15.232C125.085 15.0559 124.909 14.9385 124.721 14.9385H119.696C119.437 14.9385 119.273 15.1968 119.367 15.4316L121.258 19.8467C121.328 20.0228 121.504 20.1285 121.692 20.1285H126.671C126.929 20.1403 127.093 19.8819 126.999 19.6471Z"
      fill="#090909"
    />
    <path
      d="M86.1367 23.0876H82.0386C81.7568 23.0876 81.522 23.3225 81.522 23.6043V27.3735C81.522 27.6554 81.7568 27.8902 82.0386 27.8902H86.1367C86.4185 27.8902 86.6533 27.6554 86.6533 27.3735V23.6043C86.6533 23.3225 86.4185 23.0876 86.1367 23.0876Z"
      fill="#FCD000"
    />
    <path
      d="M77.9993 23.252C78.2342 23.252 78.4338 23.1111 78.5395 22.8997C79.4906 20.8214 81.6277 19.3301 84.0583 19.3301C86.5007 19.3301 88.6378 20.7627 89.5772 22.888C89.6711 23.0994 89.8825 23.2403 90.1173 23.2403H94.6733C94.9198 23.2403 95.0842 23.0407 95.0138 22.7941C93.7574 18.0737 89.3893 14.5393 84.0701 14.5393C78.7508 14.5393 74.371 18.062 73.1263 22.7941C73.0676 23.0407 73.2203 23.2403 73.4669 23.2403H77.9993V23.252Z"
      fill="#090909"
    />
    <path
      d="M90.1524 27.7845C89.9175 27.7845 89.7062 27.9254 89.6122 28.1367C88.6611 30.2621 86.524 31.6946 84.0934 31.6946C81.6628 31.6946 79.5257 30.2033 78.5746 28.125C78.4806 27.9136 78.2693 27.7727 78.0344 27.7727H73.5019C73.2554 27.7727 73.091 27.9723 73.1614 28.2189C74.4178 32.9393 78.7859 36.4737 84.1051 36.4737C89.4244 36.4737 93.8042 32.951 95.0489 28.2189C95.1076 27.9723 94.9549 27.7727 94.7084 27.7727H90.1524V27.7845Z"
      fill="#090909"
    />
    <path
      d="M101.48 8.32071C102.05 8.03071 102.4 7.52071 102.39 6.82071C102.38 5.73071 101.47 4.98071 100.16 4.98071H96.9002V11.9807H100.37C101.81 11.9807 102.67 11.1607 102.67 10.0207C102.67 9.21071 102.21 8.61071 101.48 8.32071ZM100.14 5.92071C100.86 5.92071 101.33 6.35071 101.33 6.94071C101.34 7.50071 100.89 7.97071 100.2 7.97071H97.9702V5.92071H100.14ZM100.35 11.0307H97.9702V8.84071H100.36C101.16 8.84071 101.59 9.35071 101.59 9.92071C101.59 10.5307 101.13 11.0307 100.35 11.0307ZM109.835 5.96071V4.98071H104.795V11.9807H109.825V10.9907H105.865V8.89071H109.325V7.95071H105.865V5.96071H109.835ZM117.505 4.98071H111.485V5.96071H113.955V11.9807H115.045V5.96071H117.505V4.98071ZM123.781 11.9807H124.911L122.141 4.98071H120.751L117.981 11.9807H119.111L119.781 10.2807H123.111L123.781 11.9807ZM120.151 9.32071L121.451 6.01071L122.741 9.32071H120.151Z"
      fill="#7F828F"
    />
  </svg>
)

export const darkBetaLogo = (
  <svg width="128" height="44" viewBox="0 0 128 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4185 28.436C41.2655 28.3303 41.0654 28.3538 40.9478 28.4947C39.3709 30.3265 37.641 30.8079 35.8523 30.8079C31.7689 30.8079 29.333 28.025 29.333 24.6198C29.333 21.2145 32.0631 18.4316 35.4052 18.4316C38.1823 18.4316 40.2299 20.0638 41.1125 22.4122C41.1713 22.5766 41.0537 22.7645 40.8654 22.7645H34.5579C34.3578 22.7645 34.2049 22.9171 34.2049 23.1167V26.1228C34.2049 26.3224 34.3578 26.475 34.5579 26.475H41.2302H46.1609C46.3962 26.475 46.5845 26.3106 46.6198 26.0758C46.7022 25.5709 46.7375 25.1129 46.7375 24.6198C46.7375 18.5608 41.6538 13.676 35.4522 13.676C29.2506 13.676 24.167 18.5608 24.167 24.6198C24.167 30.6787 29.2506 35.5635 35.4522 35.5635C39.5003 35.5635 42.6658 33.9078 44.784 31.2306C44.8782 31.1132 44.8546 30.9488 44.737 30.8549L41.4185 28.436Z"
      fill="white"
    />
    <path
      d="M56.2222 27.7549V4.98679C56.2222 4.78717 56.0693 4.63452 55.8692 4.63452H50.9268C50.7267 4.63452 50.5737 4.78717 50.5737 4.98679V27.203C50.5737 32.6749 52.4095 35.129 57.9521 35.129H58.5876C58.8464 35.129 59.0583 34.9176 59.0583 34.6593V30.7022C59.0583 30.5026 58.9053 30.3499 58.7052 30.3499H58.6229C57.1872 30.3499 56.2222 29.7159 56.2222 27.7549Z"
      fill="white"
    />
    <path
      d="M67.9898 27.7549V4.98679C67.9898 4.78717 67.8368 4.63452 67.6368 4.63452H62.6943C62.4943 4.63452 62.3413 4.78717 62.3413 4.98679V27.203C62.3413 32.6749 64.1771 35.129 69.7197 35.129H70.3551C70.614 35.129 70.8258 34.9176 70.8258 34.6593V30.7022C70.8258 30.5026 70.6729 30.3499 70.4728 30.3499H70.3904C68.9548 30.3499 67.9898 29.7159 67.9898 27.7549Z"
      fill="white"
    />
    <path
      d="M3.43213 43.2193V38.9569C3.43213 38.7573 3.58511 38.6047 3.78516 38.6047H5.7386C6.97421 38.6047 8.09215 37.4304 8.657 36.2562C8.657 36.2445 8.66877 36.2327 8.66877 36.221L8.89235 35.5399C8.98649 35.2699 8.97473 34.9763 8.85705 34.718L0.031258 14.4392C-0.0746515 14.2044 0.101863 13.946 0.360753 13.946H5.43264C5.62093 13.946 5.78567 14.0517 5.85628 14.2279L11.9167 27.6257C11.999 27.8136 12.2697 27.8136 12.3521 27.6257L18.0006 14.2513C18.0712 14.0752 18.2477 13.9695 18.436 13.9695H23.5667C23.8256 13.9695 23.9903 14.2279 23.8962 14.4627L13.5877 38.3816C12.2815 41.552 9.29246 43.5951 5.75037 43.5951H3.79693C3.59688 43.5716 3.43213 43.419 3.43213 43.2193Z"
      fill="white"
    />
    <path
      d="M119.661 14.1222C119.72 14.0282 119.85 13.9578 119.967 13.9578H124.78H124.922C125.027 13.9578 125.275 14.0752 125.204 14.1691L110.306 34.7649C110.212 34.8941 110.059 34.9763 109.894 34.9763H105.364C105.116 34.9763 104.834 34.6945 104.975 34.4831L119.661 14.1222Z"
      fill="#FCD000"
    />
    <path
      d="M96.7145 13.9695H101.763C101.951 13.9695 102.128 14.0869 102.198 14.263L110.259 34.5066C110.353 34.7414 110.177 34.988 109.93 34.988H105.128C104.94 34.988 104.764 34.8706 104.693 34.6945L96.3968 14.4509C96.2909 14.2161 96.4556 13.9695 96.7145 13.9695Z"
      fill="white"
    />
    <path
      d="M108.294 13.9695H113.342C113.53 13.9695 113.707 14.0869 113.778 14.263L121.838 34.5066C121.933 34.7414 121.756 34.988 121.509 34.988H116.708C116.519 34.988 116.343 34.8706 116.272 34.6945L107.976 14.4509C107.882 14.2161 108.047 13.9695 108.294 13.9695Z"
      fill="white"
    />
    <path
      d="M127.275 18.6664L125.428 14.2513C125.357 14.0752 125.181 13.9578 124.992 13.9578H119.956C119.697 13.9578 119.532 14.2161 119.626 14.4509L121.521 18.866C121.592 19.0421 121.768 19.1478 121.956 19.1478H126.946C127.205 19.1596 127.37 18.9012 127.275 18.6664Z"
      fill="white"
    />
    <path
      d="M86.3244 22.1069H82.2175C81.9351 22.1069 81.6997 22.3418 81.6997 22.6236V26.3928C81.6997 26.6746 81.9351 26.9095 82.2175 26.9095H86.3244C86.6068 26.9095 86.8422 26.6746 86.8422 26.3928V22.6236C86.8422 22.3418 86.6068 22.1069 86.3244 22.1069Z"
      fill="#FCD000"
    />
    <path
      d="M78.1691 22.2713C78.4045 22.2713 78.6046 22.1304 78.7105 21.919C79.6637 19.8407 81.8054 18.3494 84.2413 18.3494C86.689 18.3494 88.8307 19.782 89.7721 21.9073C89.8663 22.1187 90.0781 22.2596 90.3134 22.2596H94.8793C95.1264 22.2596 95.2912 22.0599 95.2206 21.8134C93.9614 17.093 89.5838 13.5586 84.2531 13.5586C78.9223 13.5586 74.5329 17.0813 73.2855 21.8134C73.2267 22.0599 73.3797 22.2596 73.6268 22.2596H78.1691V22.2713Z"
      fill="white"
    />
    <path
      d="M90.3485 26.8037C90.1132 26.8037 89.9014 26.9446 89.8072 27.156C88.854 29.2813 86.7123 30.7139 84.2764 30.7139C81.8405 30.7139 79.6987 29.2226 78.7456 27.1443C78.6514 26.9329 78.4396 26.792 78.2042 26.792H73.6619C73.4148 26.792 73.25 26.9916 73.3206 27.2382C74.5798 31.9586 78.9574 35.493 84.2882 35.493C89.6189 35.493 94.0083 31.9703 95.2557 27.2382C95.3145 26.9916 95.1615 26.792 94.9144 26.792H90.3485V26.8037Z"
      fill="white"
    />
    <path
      d="M101.689 7.34C102.259 7.05 102.609 6.54 102.599 5.84C102.589 4.75 101.679 4 100.369 4H97.1092V11H100.579C102.019 11 102.879 10.18 102.879 9.04C102.879 8.23 102.419 7.63 101.689 7.34ZM100.349 4.94C101.069 4.94 101.539 5.37 101.539 5.96C101.549 6.52 101.099 6.99 100.409 6.99H98.1792V4.94H100.349ZM100.559 10.05H98.1792V7.86H100.569C101.369 7.86 101.799 8.37 101.799 8.94C101.799 9.55 101.339 10.05 100.559 10.05ZM110.044 4.98V4H105.004V11H110.034V10.01H106.074V7.91H109.534V6.97H106.074V4.98H110.044ZM117.714 4H111.694V4.98H114.164V11H115.254V4.98H117.714V4ZM123.99 11H125.12L122.35 4H120.96L118.19 11H119.32L119.99 9.3H123.32L123.99 11ZM120.36 8.34L121.66 5.03L122.95 8.34H120.36Z"
      fill="#7F828F"
    />
  </svg>
)

export const darkModeLogo = (
  <svg width="137" height="36" viewBox="0 0 137 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.93921H6.75947L11.8863 22.1209L16.8127 6.93921H23.4576L14.8078 29.7684C13.0893 34.3003 11.2276 35.9997 7.41823 35.9997C5.09824 35.9997 3.37973 35.4049 1.74715 34.4702L3.89529 29.9101C4.7259 30.4199 5.78564 30.7881 6.61626 30.7881C7.70465 30.7881 8.30612 30.4482 8.85032 29.3436L0 6.93921Z"
      fill="white"
    />
    <path
      d="M102.166 7.98732H108.639L112.277 21.4979L116.516 7.90234H122.015L126.311 21.5546L130.006 7.98732H136.364L129.347 30.4483H123.59L119.237 16.7678L114.826 30.4483H109.126L102.166 7.98732Z"
      fill="white"
    />
    <path d="M90.9664 16.5415H85.5244V21.9231H90.9664V16.5415Z" fill="#FCD000" />
    <path
      d="M93.8289 21.3848C93.1129 23.679 91.1366 25.3785 88.301 25.3785C85.5801 25.3785 83.5179 23.679 82.7445 21.3848H76.2715C77.2453 26.7947 82.0285 30.845 88.2438 30.845C94.459 30.845 99.2422 26.7663 100.273 21.3848H93.8289Z"
      fill="white"
    />
    <path
      d="M88.2174 13.0857C90.9384 13.0857 93.0006 14.7851 93.7739 17.0794H100.247C99.2731 11.6695 94.4899 7.61914 88.2747 7.61914C82.0594 7.61914 77.2762 11.6978 76.2451 17.0794H82.6609C83.4056 14.7851 85.3532 13.0857 88.2174 13.0857Z"
      fill="white"
    />
    <path
      d="M46.9142 21.3561C46.9429 20.7613 47.0001 20.1948 47.0001 19.685C47.0001 13.3687 43.5631 7.59058 36.0303 7.59058C29.4714 7.59058 24.8887 12.8305 24.8887 19.2035V19.2884C24.8887 26.1145 29.901 30.8163 36.6605 30.8163C40.7276 30.8163 43.7636 29.2302 45.8831 26.6527L42.217 23.4521C40.4412 25.0666 38.8945 25.7463 36.7464 25.7463C33.8822 25.7463 31.8773 24.2452 31.2472 21.3844C31.0753 20.7046 30.9607 20.0532 30.9607 19.2884C30.9607 18.552 30.9894 18.1555 31.1612 17.3907C31.6768 14.5583 33.3953 12.6889 36.0303 12.6889C38.7227 12.6889 40.4125 14.615 40.7849 17.419H36.0017V21.3561H46.9142Z"
      fill="white"
    />
    <path
      d="M58.3725 23.594V0H52.1572V25.4634C52.1572 28.2108 54.3913 30.4201 57.1695 30.4201H58.3725H61.4945V25.2085H60.0051C59.0885 25.2085 58.3725 24.472 58.3725 23.594Z"
      fill="white"
    />
    <path
      d="M71.0034 23.8772V0H64.7881V25.7466C64.7881 28.4941 67.0221 30.7033 69.8004 30.7033H71.0034H74.1253V25.4917H72.6359C71.748 25.4917 71.0034 24.7553 71.0034 23.8772Z"
      fill="white"
    />
  </svg>
)

export const Mobile_Logo = (
  <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6019 11.8826H12.3545V19.0496H19.6019V11.8826Z" fill="#FCD000" />
    <path
      d="M23.4167 18.3325C22.4631 21.388 19.8312 23.6512 16.0549 23.6512C12.4311 23.6512 9.68473 21.388 8.65483 18.3325H0.0341797C1.33109 25.5373 7.70122 30.9315 15.9786 30.9315C24.2559 30.9315 30.6261 25.4996 31.9993 18.3325H23.4167Z"
      fill="#090909"
    />
    <path
      d="M15.9444 7.28023C19.5681 7.28023 22.3145 9.54351 23.3444 12.5989H31.9651C30.6681 5.39416 24.298 0 16.0207 0C7.74333 0 1.3732 5.43188 0 12.5989H8.54436C9.53611 9.54351 12.1299 7.28023 15.9444 7.28023Z"
      fill="#090909"
    />
  </svg>
)

export const headerLogoIcon = (
  <svg width="128" height="45" viewBox="0 0 128 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M119.402 15.103C119.461 15.009 119.59 14.9386 119.708 14.9386H124.51H124.651C124.757 14.9386 125.003 15.056 124.933 15.15L110.067 35.7458C109.973 35.8749 109.821 35.9571 109.656 35.9571H105.136C104.889 35.9571 104.607 35.6753 104.748 35.464L119.402 15.103Z"
      fill="#FCD000"
    />
    <path
      d="M41.3283 29.4167C41.1757 29.311 40.976 29.3345 40.8586 29.4754C39.2852 31.3072 37.5591 31.7886 35.7743 31.7886C31.6997 31.7886 29.2691 29.0057 29.2691 25.6005C29.2691 22.1952 31.9933 19.4123 35.3281 19.4123C38.0992 19.4123 40.1424 21.0445 41.023 23.3929C41.0817 23.5573 40.9643 23.7452 40.7764 23.7452H34.4826C34.283 23.7452 34.1303 23.8978 34.1303 24.0975V27.1035C34.1303 27.3031 34.283 27.4557 34.4826 27.4557H41.1404H46.0604C46.2953 27.4557 46.4831 27.2913 46.5184 27.0565C46.6006 26.5516 46.6358 26.0936 46.6358 25.6005C46.6358 19.5415 41.5632 14.6567 35.375 14.6567C29.1869 14.6567 24.1143 19.5415 24.1143 25.6005C24.1143 31.6594 29.1869 36.5442 35.375 36.5442C39.4143 36.5442 42.573 34.8885 44.6866 32.2113C44.7805 32.0939 44.757 31.9295 44.6396 31.8356L41.3283 29.4167Z"
      fill="#090909"
    />
    <path
      d="M56.1001 28.7356V5.9675C56.1001 5.76788 55.9475 5.61523 55.7479 5.61523H50.8161C50.6165 5.61523 50.4639 5.76788 50.4639 5.9675V28.1837C50.4639 33.6556 52.2957 36.1097 57.8262 36.1097H58.4603C58.7186 36.1097 58.93 35.8984 58.93 35.64V31.6829C58.93 31.4833 58.7773 31.3306 58.5777 31.3306H58.4955C57.063 31.3306 56.1001 30.6966 56.1001 28.7356Z"
      fill="#090909"
    />
    <path
      d="M67.8423 28.7356V5.9675C67.8423 5.76788 67.6897 5.61523 67.49 5.61523H62.5583C62.3587 5.61523 62.2061 5.76788 62.2061 5.9675V28.1837C62.2061 33.6556 64.0378 36.1097 69.5684 36.1097H70.2025C70.4608 36.1097 70.6722 35.8984 70.6722 35.64V31.6829C70.6722 31.4833 70.5195 31.3306 70.3199 31.3306H70.2377C68.8052 31.3306 67.8423 30.6966 67.8423 28.7356Z"
      fill="#090909"
    />
    <path
      d="M3.42468 44.2V39.9376C3.42468 39.738 3.57733 39.5854 3.77695 39.5854H5.72616C6.95909 39.5854 8.07459 38.4111 8.63822 37.2369C8.63822 37.2252 8.64996 37.2134 8.64996 37.2017L8.87306 36.5207C8.967 36.2506 8.95526 35.957 8.83784 35.6987L0.0311902 15.4199C-0.0744896 15.1851 0.101642 14.9268 0.35997 14.9268H5.42086C5.60873 14.9268 5.77312 15.0324 5.84358 15.2086L11.8908 28.6064C11.973 28.7943 12.2431 28.7943 12.3253 28.6064L17.9615 15.2321C18.032 15.0559 18.2081 14.9502 18.396 14.9502H23.5156C23.7739 14.9502 23.9383 15.2086 23.8444 15.4434L13.5582 39.3623C12.2548 42.5327 9.2723 44.5758 5.7379 44.5758H3.78869C3.58908 44.5523 3.42468 44.3997 3.42468 44.2Z"
      fill="#090909"
    />
    <path
      d="M96.5048 14.9502H101.542C101.73 14.9502 101.906 15.0676 101.977 15.2438L110.02 35.4873C110.114 35.7221 109.938 35.9687 109.691 35.9687H104.9C104.713 35.9687 104.536 35.8513 104.466 35.6752L96.1878 15.4316C96.0821 15.1968 96.2465 14.9502 96.5048 14.9502Z"
      fill="#090909"
    />
    <path
      d="M108.059 14.9502H113.097C113.284 14.9502 113.461 15.0676 113.531 15.2438L121.574 35.4873C121.668 35.7221 121.492 35.9687 121.246 35.9687H116.455C116.267 35.9687 116.091 35.8513 116.02 35.6752L107.742 15.4316C107.648 15.1968 107.813 14.9502 108.059 14.9502Z"
      fill="#090909"
    />
    <path
      d="M126.999 19.6471L125.156 15.232C125.085 15.0559 124.909 14.9385 124.721 14.9385H119.696C119.437 14.9385 119.273 15.1968 119.367 15.4316L121.258 19.8467C121.328 20.0228 121.504 20.1285 121.692 20.1285H126.671C126.929 20.1403 127.093 19.8819 126.999 19.6471Z"
      fill="#090909"
    />
    <path
      d="M86.1367 23.0876H82.0386C81.7568 23.0876 81.522 23.3225 81.522 23.6043V27.3735C81.522 27.6554 81.7568 27.8902 82.0386 27.8902H86.1367C86.4185 27.8902 86.6533 27.6554 86.6533 27.3735V23.6043C86.6533 23.3225 86.4185 23.0876 86.1367 23.0876Z"
      fill="#FCD000"
    />
    <path
      d="M77.9993 23.252C78.2342 23.252 78.4338 23.1111 78.5395 22.8997C79.4906 20.8214 81.6277 19.3301 84.0583 19.3301C86.5007 19.3301 88.6378 20.7627 89.5772 22.888C89.6711 23.0994 89.8824 23.2403 90.1173 23.2403H94.6733C94.9198 23.2403 95.0842 23.0407 95.0138 22.7941C93.7574 18.0737 89.3893 14.5393 84.0701 14.5393C78.7508 14.5393 74.371 18.062 73.1263 22.7941C73.0676 23.0407 73.2203 23.2403 73.4669 23.2403H77.9993V23.252Z"
      fill="#090909"
    />
    <path
      d="M90.1524 27.7845C89.9175 27.7845 89.7062 27.9254 89.6122 28.1367C88.6611 30.2621 86.524 31.6946 84.0934 31.6946C81.6628 31.6946 79.5257 30.2033 78.5746 28.125C78.4806 27.9136 78.2693 27.7727 78.0344 27.7727H73.5019C73.2554 27.7727 73.091 27.9723 73.1614 28.2189C74.4178 32.9393 78.7859 36.4737 84.1051 36.4737C89.4244 36.4737 93.8042 32.951 95.0489 28.2189C95.1076 27.9723 94.9549 27.7727 94.7084 27.7727H90.1524V27.7845Z"
      fill="#090909"
    />
    <path
      d="M101.48 8.32071C102.05 8.03071 102.4 7.52071 102.39 6.82071C102.38 5.73071 101.47 4.98071 100.16 4.98071H96.9002V11.9807H100.37C101.81 11.9807 102.67 11.1607 102.67 10.0207C102.67 9.21071 102.21 8.61071 101.48 8.32071ZM100.14 5.92071C100.86 5.92071 101.33 6.35071 101.33 6.94071C101.34 7.50071 100.89 7.97071 100.2 7.97071H97.9702V5.92071H100.14ZM100.35 11.0307H97.9702V8.84071H100.36C101.16 8.84071 101.59 9.35071 101.59 9.92071C101.59 10.5307 101.13 11.0307 100.35 11.0307ZM109.835 5.96071V4.98071H104.795V11.9807H109.825V10.9907H105.865V8.89071H109.325V7.95071H105.865V5.96071H109.835ZM117.505 4.98071H111.485V5.96071H113.955V11.9807H115.045V5.96071H117.505V4.98071ZM123.781 11.9807H124.911L122.141 4.98071H120.751L117.981 11.9807H119.111L119.781 10.2807H123.111L123.781 11.9807ZM120.151 9.32071L121.451 6.01071L122.741 9.32071H120.151Z"
      fill="#7F828F"
    />
  </svg>
)

export const headerDarkModeIcon = (
  <svg width="128" height="44" viewBox="0 0 128 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4185 28.436C41.2655 28.3303 41.0654 28.3538 40.9478 28.4947C39.3709 30.3265 37.641 30.8079 35.8523 30.8079C31.7689 30.8079 29.333 28.025 29.333 24.6198C29.333 21.2145 32.0631 18.4316 35.4052 18.4316C38.1823 18.4316 40.2299 20.0638 41.1125 22.4122C41.1713 22.5766 41.0537 22.7645 40.8654 22.7645H34.5579C34.3578 22.7645 34.2049 22.9171 34.2049 23.1167V26.1228C34.2049 26.3224 34.3578 26.475 34.5579 26.475H41.2302H46.1609C46.3962 26.475 46.5845 26.3106 46.6198 26.0758C46.7022 25.5709 46.7375 25.1129 46.7375 24.6198C46.7375 18.5608 41.6538 13.676 35.4522 13.676C29.2506 13.676 24.167 18.5608 24.167 24.6198C24.167 30.6787 29.2506 35.5635 35.4522 35.5635C39.5003 35.5635 42.6658 33.9078 44.784 31.2306C44.8782 31.1132 44.8546 30.9488 44.737 30.8549L41.4185 28.436Z"
      fill="white"
    />
    <path
      d="M56.2222 27.7549V4.98679C56.2222 4.78717 56.0693 4.63452 55.8692 4.63452H50.9268C50.7267 4.63452 50.5737 4.78717 50.5737 4.98679V27.203C50.5737 32.6749 52.4095 35.129 57.9521 35.129H58.5876C58.8464 35.129 59.0583 34.9176 59.0583 34.6593V30.7022C59.0583 30.5026 58.9053 30.3499 58.7052 30.3499H58.6229C57.1872 30.3499 56.2222 29.7159 56.2222 27.7549Z"
      fill="white"
    />
    <path
      d="M67.9898 27.7549V4.98679C67.9898 4.78717 67.8368 4.63452 67.6368 4.63452H62.6943C62.4943 4.63452 62.3413 4.78717 62.3413 4.98679V27.203C62.3413 32.6749 64.1771 35.129 69.7197 35.129H70.3551C70.614 35.129 70.8258 34.9176 70.8258 34.6593V30.7022C70.8258 30.5026 70.6729 30.3499 70.4728 30.3499H70.3904C68.9548 30.3499 67.9898 29.7159 67.9898 27.7549Z"
      fill="white"
    />
    <path
      d="M3.43213 43.2193V38.9569C3.43213 38.7573 3.58511 38.6047 3.78516 38.6047H5.7386C6.97421 38.6047 8.09215 37.4304 8.657 36.2562C8.657 36.2445 8.66877 36.2327 8.66877 36.221L8.89235 35.5399C8.98649 35.2699 8.97473 34.9763 8.85705 34.718L0.031258 14.4392C-0.0746515 14.2044 0.101863 13.946 0.360753 13.946H5.43264C5.62093 13.946 5.78567 14.0517 5.85628 14.2279L11.9167 27.6257C11.999 27.8136 12.2697 27.8136 12.3521 27.6257L18.0006 14.2513C18.0712 14.0752 18.2477 13.9695 18.436 13.9695H23.5667C23.8256 13.9695 23.9903 14.2279 23.8962 14.4627L13.5877 38.3816C12.2815 41.552 9.29246 43.5951 5.75037 43.5951H3.79693C3.59688 43.5716 3.43213 43.419 3.43213 43.2193Z"
      fill="white"
    />
    <path
      d="M119.661 14.1222C119.72 14.0282 119.85 13.9578 119.967 13.9578H124.78H124.922C125.027 13.9578 125.275 14.0752 125.204 14.1691L110.306 34.7649C110.212 34.8941 110.059 34.9763 109.894 34.9763H105.364C105.116 34.9763 104.834 34.6945 104.975 34.4831L119.661 14.1222Z"
      fill="#FCD000"
    />
    <path
      d="M96.7145 13.9695H101.763C101.951 13.9695 102.128 14.0869 102.198 14.263L110.259 34.5066C110.353 34.7414 110.177 34.988 109.93 34.988H105.128C104.94 34.988 104.764 34.8706 104.693 34.6945L96.3968 14.4509C96.2909 14.2161 96.4556 13.9695 96.7145 13.9695Z"
      fill="white"
    />
    <path
      d="M108.294 13.9695H113.342C113.53 13.9695 113.707 14.0869 113.778 14.263L121.838 34.5066C121.933 34.7414 121.756 34.988 121.509 34.988H116.708C116.519 34.988 116.343 34.8706 116.272 34.6945L107.976 14.4509C107.882 14.2161 108.047 13.9695 108.294 13.9695Z"
      fill="white"
    />
    <path
      d="M127.275 18.6664L125.428 14.2513C125.357 14.0752 125.181 13.9578 124.992 13.9578H119.956C119.697 13.9578 119.532 14.2161 119.626 14.4509L121.521 18.866C121.592 19.0421 121.768 19.1478 121.956 19.1478H126.946C127.205 19.1596 127.37 18.9012 127.275 18.6664Z"
      fill="white"
    />
    <path
      d="M86.3244 22.1069H82.2175C81.9351 22.1069 81.6997 22.3418 81.6997 22.6236V26.3928C81.6997 26.6746 81.9351 26.9095 82.2175 26.9095H86.3244C86.6068 26.9095 86.8422 26.6746 86.8422 26.3928V22.6236C86.8422 22.3418 86.6068 22.1069 86.3244 22.1069Z"
      fill="#FCD000"
    />
    <path
      d="M78.1691 22.2713C78.4045 22.2713 78.6046 22.1304 78.7105 21.919C79.6637 19.8407 81.8054 18.3494 84.2413 18.3494C86.689 18.3494 88.8307 19.782 89.7721 21.9073C89.8663 22.1187 90.0781 22.2596 90.3134 22.2596H94.8793C95.1264 22.2596 95.2912 22.0599 95.2206 21.8134C93.9614 17.093 89.5838 13.5586 84.2531 13.5586C78.9223 13.5586 74.5329 17.0813 73.2855 21.8134C73.2267 22.0599 73.3797 22.2596 73.6268 22.2596H78.1691V22.2713Z"
      fill="white"
    />
    <path
      d="M90.3485 26.8037C90.1132 26.8037 89.9014 26.9446 89.8072 27.156C88.854 29.2813 86.7123 30.7139 84.2764 30.7139C81.8405 30.7139 79.6987 29.2226 78.7456 27.1443C78.6514 26.9329 78.4396 26.792 78.2042 26.792H73.6619C73.4148 26.792 73.25 26.9916 73.3206 27.2382C74.5798 31.9586 78.9574 35.493 84.2882 35.493C89.6189 35.493 94.0083 31.9703 95.2557 27.2382C95.3145 26.9916 95.1615 26.792 94.9144 26.792H90.3485V26.8037Z"
      fill="white"
    />
    <path
      d="M101.689 7.34C102.259 7.05 102.609 6.54 102.599 5.84C102.589 4.75 101.679 4 100.369 4H97.1092V11H100.579C102.019 11 102.879 10.18 102.879 9.04C102.879 8.23 102.419 7.63 101.689 7.34ZM100.349 4.94C101.069 4.94 101.539 5.37 101.539 5.96C101.549 6.52 101.099 6.99 100.409 6.99H98.1792V4.94H100.349ZM100.559 10.05H98.1792V7.86H100.569C101.369 7.86 101.799 8.37 101.799 8.94C101.799 9.55 101.339 10.05 100.559 10.05ZM110.044 4.98V4H105.004V11H110.034V10.01H106.074V7.91H109.534V6.97H106.074V4.98H110.044ZM117.714 4H111.694V4.98H114.164V11H115.254V4.98H117.714V4ZM123.99 11H125.12L122.35 4H120.96L118.19 11H119.32L119.99 9.3H123.32L123.99 11ZM120.36 8.34L121.66 5.03L122.95 8.34H120.36Z"
      fill="#7F828F"
    />
  </svg>
)

export const yellowTokenIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"
      fill="#2D2A26"
    />
    <path
      d="M20.6621 14.8784H15.378C15.2013 14.8794 15.0322 14.95 14.9072 15.0749C14.7823 15.1999 14.7117 15.3691 14.7107 15.5458V20.4068C14.7117 20.5835 14.7823 20.7527 14.9072 20.8776C15.0322 21.0025 15.2013 21.0732 15.378 21.0741H20.6621C20.8388 21.0732 21.008 21.0025 21.1329 20.8776C21.2579 20.7527 21.3285 20.5835 21.3294 20.4068V15.5458C21.3285 15.3691 21.2579 15.1999 21.1329 15.0749C21.008 14.95 20.8388 14.8794 20.6621 14.8784Z"
      fill="white"
    />
    <path
      d="M10.1669 15.0899C10.3137 15.0873 10.4568 15.0435 10.58 14.9635C10.7031 14.8836 10.8013 14.7707 10.8635 14.6377C11.4891 13.2725 12.4911 12.114 13.7519 11.2982C15.0127 10.4824 16.4801 10.0431 17.9818 10.0319C19.4869 10.0257 20.9612 10.4574 22.2252 11.2746C23.4891 12.0918 24.488 13.259 25.1001 14.634C25.1599 14.7691 25.2574 14.884 25.381 14.9648C25.5046 15.0457 25.6489 15.0891 25.7966 15.0899H31.6751C31.7466 15.0945 31.8181 15.0813 31.8833 15.0515C31.9484 15.0218 32.0052 14.9763 32.0486 14.9193C32.0919 14.8622 32.1204 14.7953 32.1316 14.7245C32.1428 14.6538 32.1363 14.5813 32.1126 14.5137C31.236 11.4463 29.384 8.7479 26.837 6.82687C24.29 4.90583 21.1866 3.8667 17.9964 3.8667C14.8061 3.8667 11.7027 4.90583 9.15571 6.82687C6.60872 8.7479 4.75676 11.4463 3.88007 14.5137C3.85817 14.5816 3.85305 14.6538 3.86518 14.7241C3.87731 14.7944 3.90631 14.8608 3.94969 14.9174C3.99308 14.9741 4.04955 15.0193 4.11428 15.0494C4.179 15.0794 4.25005 15.0933 4.32132 15.0899H10.156H10.1669Z"
      fill="#FCD000"
    />
    <path
      d="M25.844 20.9357C25.6963 20.9364 25.552 20.9798 25.4284 21.0607C25.3049 21.1416 25.2073 21.2564 25.1475 21.3915C24.5294 22.7619 23.5283 23.9245 22.2647 24.7391C21.0011 25.5536 19.5289 25.9855 18.0255 25.9827C16.5261 25.9676 15.0619 25.5267 13.8035 24.7112C12.5451 23.8957 11.5444 22.7394 10.9182 21.3769C10.8584 21.2418 10.7608 21.127 10.6373 21.0461C10.5137 20.9652 10.3693 20.9218 10.2217 20.9211H4.35415C4.28265 20.9165 4.21112 20.9296 4.14594 20.9594C4.08077 20.9892 4.02398 21.0346 3.98065 21.0917C3.93731 21.1487 3.90878 21.2156 3.89759 21.2864C3.88641 21.3572 3.89292 21.4296 3.91655 21.4972C4.79323 24.5646 6.6452 27.263 9.1922 29.1841C11.7392 31.1051 14.8426 32.1442 18.0328 32.1442C21.2231 32.1442 24.3265 31.1051 26.8735 29.1841C29.4205 27.263 31.2725 24.5646 32.1491 21.4972C32.1702 21.4294 32.1746 21.3574 32.1621 21.2875C32.1496 21.2175 32.1206 21.1516 32.0774 21.0952C32.0342 21.0387 31.9781 20.9935 31.9138 20.9632C31.8495 20.9329 31.7789 20.9185 31.7079 20.9211H25.844V20.9357Z"
      fill="#FCD000"
    />
  </svg>
)

export const opendaxIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"
      fill="#F4F4F4"
    />
    <path
      d="M7.12629 27.0904C6.66437 27.6979 7.09761 28.5716 7.86076 28.5716H10.5774C10.8682 28.5716 11.142 28.4345 11.3162 28.2016L25.696 8.9798C26.1512 8.37139 25.717 7.50439 24.9572 7.50439H22.4771C22.1888 7.50439 21.9171 7.63913 21.7426 7.86859L7.12629 27.0904Z"
      fill="#1BE6E6"
    />
    <path
      d="M28.5048 10.7029C28.8504 11.2962 28.5776 11.4484 28.2912 11.4484H24.9466C24.6701 11.4484 24.2532 11.3713 24.0677 11.0756L22.0587 8.24545C21.7525 7.78144 21.836 7.5 22.3199 7.5H25.7687C26.0748 7.5 26.3279 7.62931 26.4859 7.87272L28.5048 10.7029Z"
      fill="black"
    />
    <path
      d="M22.1917 28.2347C22.3661 28.4654 22.6385 28.601 22.9277 28.601H25.7731C26.5367 28.601 26.9698 27.7263 26.507 27.119L11.832 7.8634C11.6575 7.6344 11.3861 7.5 11.0982 7.5H8.36991C7.60794 7.5 7.17437 8.37132 7.6339 8.97914L22.1917 28.2347Z"
      fill="black"
    />
  </svg>
)
