interface CrossIconProps {
  fill?: string
  stroke?: string
}

export const CrossIcon = ({ fill, stroke }: CrossIconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
      fill={fill}
    />
    <path d="M6 18L18 6M6 6L18 18" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
