import { FeaturedStar } from 'assets/images/FeaturedStar'
import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface FeaturedBadgeProps {
  additionalClassName?: string
  badgeColor?: string
  starColor?: string
}

export const FeaturedBadge: React.FC<FeaturedBadgeProps> = ({
  additionalClassName,
  badgeColor,
  starColor,
}: FeaturedBadgeProps) => {
  return (
    <span
      className={classNames(
        'pl-[6px] pr-[10px] py-px w-auto flex items-center text-sm font-metro-semibold rounded w-fit whitespace-nowrap',
        additionalClassName,
        badgeColor,
      )}
    >
      <FeaturedStar className={`mr-1 ${starColor}`} />
      <FormattedMessage id="page.asset.badge.featured.article" />
    </span>
  )
}

FeaturedBadge.defaultProps = {
  badgeColor: 'bg-primary-cta-color-20 text-primary-cta-layer-color-60',
  starColor: 'text-primary-cta-color-90',
}
