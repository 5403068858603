import {
  HeaderProps,
  Layout as SharedLayout,
  LoginButtonWidget,
  LoginModal,
  MarketSelectorWidget,
  navigationAdmin,
  navigationApp,
  navigationAppItem,
  RootState,
  SidebarProps,
  toggleLoginModalOpen,
  useAppDispatch,
  useAppSelector,
  WalletConnectedState,
  isBrowser,
} from '@openware/opendax-web-sdk'
import { GoogleIcon } from 'assets/images/GoogleIcon'
import { betaLogo, darkBetaLogo, headerDarkModeIcon, headerLogoIcon } from 'assets/images/Logo'
import {
  navigation,
  navigationAuthorizedYellow,
  navigationLoggedin,
  navigationMobile,
  navigationYellow,
} from 'configs/navigation'
import { useAnalyticsInit } from 'hooks/useAnalyticsInit'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { FormattedMessage, useIntl } from 'react-intl'
import * as tw from 'tailwind.config'
import { CustomFooter } from './CustomFooter'
import { CustomHeader } from './CustomHeader'
import { TestnetDisclaimer } from './TestnetDisclaimer'
const textColors = tw.theme.extend.textColor

interface HeaderNavigations {
  name: string | React.ReactNode
  href: string
}

interface HeaderOptions {
  navigations: HeaderNavigations[]
  customHeaderContent?: React.ReactNode
  responseIsMobile?: boolean
}

interface LayoutProps {
  className?: string
  headerOptions?: HeaderOptions
  hideFooter?: boolean
  hideHeader?: boolean
  hideOptionsMenu?: boolean
  actionButtonProps?: {
    text: JSX.Element | string
    link: string
  }
}

const currentMarketSelector = (state: RootState) => state.markets.currentMarket
const marketsSelector = (state: RootState) => state.markets.markets
const userSelector = (state: RootState) => state.user
const themeSelector = (state: RootState) => state.globalSettings.color

export function AppLayout({
  className,
  headerOptions,
  hideFooter,
  hideHeader,
  hideOptionsMenu = false,
  actionButtonProps,
  children,
}: PropsWithChildren<LayoutProps>): JSX.Element {
  const markets = useAppSelector(marketsSelector)
  const { user, userLoading } = useAppSelector(userSelector)
  const currentMarket = useAppSelector(currentMarketSelector)
  const themeSwitcher: string = useAppSelector(themeSelector)
  const isSetupMode: boolean = useAppSelector(state => state.globalSettings.isSetupMode)
  const isLoginModalOpen = useAppSelector(state => state.globalSettings.isLoginModalOpen)

  const userRole = user.role
  const isAdminWriter = ['superadmin', 'writer'].includes(userRole)
  const router = useRouter()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 1)
  }, [router.asPath])

  const [headerLogo, setHeaderlogo] = useState<any>()
  const [sideBarLogo, setSideBarLogo] = useState<any>()

  const [cookies, setCookie] = useCookies(['role'])

  const isLoggedin = useMemo(() => user?.id, [user?.id])
  const isAdmin = useMemo(() => ['admin', 'superadmin'].includes(user?.role), [user?.role])
  const isReloadOnRoleChanged = useMemo(
    () => ['/articles/[...slug]', '/news/[page]'].includes(router.pathname),
    [router.pathname],
  )

  useAnalyticsInit(user)

  const changeLogos = useCallback(() => {
    if (themeSwitcher === 'dark') {
      setHeaderlogo(headerDarkModeIcon)
      setSideBarLogo(darkBetaLogo)
    } else {
      setHeaderlogo(headerLogoIcon)
      setSideBarLogo(betaLogo)
    }
  }, [themeSwitcher])

  useEffect(() => {
    window.addEventListener('load', changeLogos)
    return () => {
      window.removeEventListener('load', changeLogos)
    }
  }, [changeLogos])

  useEffect(() => {
    changeLogos()
  }, [themeSwitcher])

  useEffect(() => {
    if (userLoading) {
      return
    }

    if (cookies.role !== userRole) {
      setCookie('role', userRole, { path: '/' })
      isReloadOnRoleChanged && router.reload()
    }
  }, [cookies.role, userRole, userLoading])

  useEffect(() => {
    if (isBrowser()) {
      const element = document.querySelector('a[aria-label="Trade"]')
      const elementWallet = document.querySelector('a[aria-label="Wallet"]')

      // Check if the element exists to avoid errors
      if (element && elementWallet) {
        // Update the class of the selected element
        element.className =
          'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col'

        elementWallet.className =
          'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col'
      }
    }
  }, [isBrowser()])

  const navigations = useMemo((): navigationApp[] => {
    const mainNavigation = isLoggedin ? navigationLoggedin : navigation
    const mainYellowNavigation =
      userRole === 'superadmin' || userRole === 'writer' ? navigationAuthorizedYellow : navigationYellow

    return [
      {
        app: intl.formatMessage({
          id: 'page.sidebar.navigation.separator.list',
        }),
        pathnames: mainYellowNavigation.map<navigationAppItem>((nav: navigationAppItem) => {
          return {
            ...nav,
            name: intl.formatMessage({
              id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
            }),
            path:
              nav.path === '/trading' && markets?.length
                ? currentMarket
                  ? `${nav.path}/${currentMarket.id}`
                  : `${nav.path}/${markets[0].id}`
                : nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
      {
        app: intl.formatMessage({
          id: 'page.sidebar.navigation.separator.trade',
        }),
        pathnames: mainNavigation.map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: intl.formatMessage({
                id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
              }),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: intl.formatMessage({
                    id: `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                  }),
                }
              }),
              skipUseCurrentPath: true,
            }
          }

          return {
            ...nav,
            name: intl.formatMessage({
              id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
            }),
            path:
              nav.path === '/trading' && markets?.length
                ? currentMarket
                  ? `${nav.path}/${currentMarket.id}`
                  : `${nav.path}/${markets[0].id}`
                : nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
    ]
  }, [isLoggedin, markets, currentMarket, userRole])

  const mobileNavigation = useMemo((): navigationApp[] => {
    if (!navigationMobile) return []

    return [
      {
        app: 'mainapp',
        pathnames: navigationMobile.map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: intl.formatMessage({
                id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
              }),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: intl.formatMessage({
                    id: `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                  }),
                }
              }),
            }
          }

          return {
            ...nav,
            name: intl.formatMessage({
              id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
            }),
            path:
              nav.path === '/trading' && markets?.length
                ? currentMarket
                  ? `${nav.path}/${currentMarket.id}`
                  : `${nav.path}/${markets[0].id}`
                : nav.path,
          }
        }),
      },
    ]
  }, [navigationMobile])

  const adminNavigation = useMemo((): navigationApp[] => {
    if (!isAdmin) return []
    return [
      {
        app: 'mainapp',
        pathnames: [].map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: intl.formatMessage({
                id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
              }),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: intl.formatMessage({
                    id: `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                  }),
                }
              }),
              skipUseCurrentPath: true,
            }
          }

          return {
            ...nav,
            name: intl.formatMessage({
              id: `page.sidebar.navigation.${nav.name.toLowerCase()}`,
            }),
            path: nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
    ]
  }, [isAdmin, navigationAdmin])

  const buttonsList = useMemo(() => {
    if (isBrowser() && window.localStorage.getItem('show_auth_btn') === 'true') {
      return isLoggedin
        ? [
            {
              name: 'Metamask',
              component: (
                <LoginButtonWidget buttonClassNames="inline-flex w-full justify-center px-4 py-2 border border-transparent text-sm font-metro-medium rounded-md shadow-sm text-primary-cta-layer-color-60 duration-200 bg-primary-cta-color-60 hover:bg-primary-cta-color-80 active:bg-primary-cta-color-90" />
              ),
              label: intl.formatMessage({
                id: 'page.sidebar.bottom.account',
              }),
            },
          ]
        : [
            {
              name: 'Metamask',
              component: (
                <LoginButtonWidget buttonClassNames="flex justify-center items-center h-10 cursor-pointer rounded-md border border-transparent text-primary-cta-layer-color-60 duration-200 bg-primary-cta-color-60 hover:bg-primary-cta-color-80  active:bg-primary-cta-color-90" />
              ),
              label: intl.formatMessage({
                id: 'page.sidebar.bottom.account',
              }),
            },
          ]
    } else {
      return []
    }
  }, [isLoggedin, isBrowser()])

  const adminButtonsList = useMemo(() => {
    if (!isAdmin) return []
    return [
      {
        name: 'admin',
        label: intl.formatMessage({
          id: 'page.sidebar.navigation.admin',
        }),
        path: '/admin/project/default',
        newTab: true,
      },
      {
        name: 'user',
        label: intl.formatMessage({
          id: 'page.sidebar.navigation.user',
        }),
        path: '/trading',
      },
    ]
  }, [isAdmin])

  const sidebarProps: SidebarProps = {
    currentApp: 'mainapp',
    navigations,
    mobileNavigation,
    adminNavigation,
    adminButtonsList,
    showAdminNavigation: isAdmin,
    classNames: 'bg-navbar-background-color sm:border-r border-divider-color-20 fixed z-[30] with-scrollbar',
    bottomClasses:
      'fixed w-screen bottom-0 z-30 flex-shrink-0 md:hidden flex h-16 bg-navbar-background-color border-t border-divider-color-20 w-full left-0',
    navActiveClassNames: 'bg-navbar-control-bg-color-10 text-navbar-control-layer-color-60',
    navInactiveClassNames: 'text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10',
    navOverlayClasses: 'relative bg-navbar-background-color flex-1 flex flex-col max-w-[260px] pt-5 pb-4',
    navOverlayClassNames:
      'relative no-underline duration-150 group flex items-center mt-1 px-2 py-2 text-md font-metro-bold rounded-md',
    navMinimizedClassNames:
      'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md',
    isLoggedin: false,
    buttonsList,
    logo: sideBarLogo,
    linkOnLogo: '/',
    showMobileSidebarToggler: true,
    mobileNavbarClasses: 'text-xs font-metro-semibold text-neutral-control-layer-color-60 mx-2.5 my-1 rounded',
    mobileNavbarActiveClasses: 'bg-navbar-control-bg-color-10',
    mobileSpanActiveColor: 'text-neutral-control-layer-color-100',
    showNavGroupTitles: true,
    navMoreLabel: <FormattedMessage id="page.sidebar.navigation.more" />,
    exceptedDefaultActivePathnames: ['/404', '/trading', '/assets', '/news', '/articles', '/settings', '/balances'],
  }

  const renderLoginModal = () => {
    if (isBrowser() && window.localStorage.getItem('show_auth_btn') === 'true') {
      return (
        <LoginModal
          open={isLoginModalOpen}
          setOpen={() => dispatch(toggleLoginModalOpen())}
          authProvidersButtonDisabledClassName={`mb-3 min-w-full h-9 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-gray-300 text-base font-metro-medium ${
            themeSwitcher === 'dark' ? 'fill-neutral-control-layer-color-50' : 'text-gray-400'
          } focus:outline-none focus:ring-none sm:mt-0 sm:w-auto sm:text-sm duration-200`}
          authProvidersButtonActiveClassName={`mb-3 min-w-full h-9 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-white text-base font-metro-medium ${
            themeSwitcher === 'dark' ? 'fill-neutral-control-layer-color-10' : 'text-gray-700'
          } hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm duration-200`}
          authProvidersButtonLabel={(providerName: string, providerIcon: JSX.Element, isButtonEnabled: boolean) => (
            <div className="flex flex-row justify-center items-center">
              {providerName === 'Google' && themeSwitcher === 'dark' ? (
                <GoogleIcon isButtonEnabled={isButtonEnabled} />
              ) : (
                providerIcon
              )}
              <div className="h-5 w-max -mt-0.5 ml-1.5">{providerName}</div>
            </div>
          )}
          termsLink="/terms_of_service"
        />
      )
    }

    return null
  }

  if (hideHeader) {
    return (
      <SharedLayout
        containerClassName={className}
        sidebarProps={sidebarProps}
        customHeader={<></>}
        customFooter={hideFooter ? null : <CustomFooter />}
        mainClassName="flex-1 flex flex-col relative overflow-y-auto overflow-x-hidden focus:outline-none md:ml-20"
      >
        {children}
        <MarketSelectorWidget />
        <TestnetDisclaimer />
        <WalletConnectedState />
        {renderLoginModal()}
      </SharedLayout>
    )
  }

  const customHeaderProps = () => {
    const headerProps: HeaderProps = {
      options: headerOptions,
      headerLogoIcon: headerLogo,
    }

    if (headerOptions) {
      return headerProps
    }
    return {}
  }

  const computedActionButtonProps = () => {
    const defaultActionButtonProps = {
      text: <FormattedMessage id="header.button.wallet" />,
      link: 'https://wallet.yellow.com',
    }

    if (isAdminWriter && actionButtonProps) {
      return actionButtonProps
    }

    return defaultActionButtonProps
  }

  return (
    <SharedLayout
      containerClassName={className}
      sidebarProps={sidebarProps}
      customHeader={
        <CustomHeader
          actionButtonProps={computedActionButtonProps()}
          hideOptionsMenu={hideOptionsMenu}
          {...customHeaderProps()}
        />
      }
      customFooter={hideFooter ? null : <CustomFooter />}
      mainClassName="flex-1 flex flex-col relative overflow-y-auto overflow-x-hidden focus:outline-none md:ml-20"
    >
      {children}
      <MarketSelectorWidget />
      <WalletConnectedState />
      <TestnetDisclaimer />
      {renderLoginModal()}
    </SharedLayout>
  )
}

export const Layout = React.memo(AppLayout)
