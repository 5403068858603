import { User } from '@openware/opendax-web-sdk/types'
import { analytics, isUserPresentInDataLayer } from 'helpers/analytics'
import { useCallback, useEffect } from 'react'

export const useAnalyticsInit = (user: User) => {
  const updateAnalyticsInit = useCallback(async () => {
    if (!user.id || isUserPresentInDataLayer(user.id)) {
      return
    }

    await analytics({
      type: 'init',
      params: {
        userId: user.id,
      },
    })

    await analytics({
      type: 'userProperties',
      params: {
        propertyName: 'user_id',
        propertyValue: user.id,
      },
    })

    await analytics({
      type: 'userProperties',
      params: {
        propertyName: 'user_role',
        propertyValue: user.role,
      },
    })

    await analytics({
      type: 'userProperties',
      params: {
        propertyName: 'created_at ',
        propertyValue: user.created_at,
      },
    })
  }, [user])

  useEffect(() => {
    updateAnalyticsInit()
  }, [updateAnalyticsInit])
}
