import { CoreProvider } from '@openware/opendax-web-sdk'
import '@openware/opendax-web-sdk/index.css'
import ErrorBoundary from 'components/ErrorBoundary'
import { getPublicConfig } from 'configs/app'
import { appTitle } from 'helpers'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { CookiesProvider } from 'react-cookie'
import { languageMap } from 'translations'
import '../styles/globals.css'
import '../styles/scss/index.scss'

const Alerts = dynamic(() => import('@openware/opendax-web-sdk').then((mod: any) => mod.Alerts), {
  ssr: false,
}) as any

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const publicRuntimeConfig = getPublicConfig()

  const gitCommitSha =
    publicRuntimeConfig.publicGitCommitSha || // comes from the Vercel deployment
    publicRuntimeConfig.gitCommitSha // comes from the Drone config through the Docker build

  return (
    <>
      <Head>
        <meta name="git-commit" content={gitCommitSha} />
        <meta name="viewport" content="width=device-width, initial-scale=1" charSet="utf-8" />
        { publicRuntimeConfig.rootUrl !== 'https://yellow.com' && <meta name="robots" content='noindex, nofollow' /> }
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <title>{appTitle()}</title>
      </Head>
      <CookiesProvider>
        <CoreProvider customLanguageMap={languageMap}>
          <ErrorBoundary>
            <div className="bg-main-background-color text-text-color-90 min-h-screen">
              <Alerts
                cancelAllOrderConfirmClassName="mr-3 inline-flex items-center p-2 border border-primary-cta-layer-color-60 text-sm leading-4 font-medium rounded-sm shadow-sm text-primary-cta-layer-color-60 bg-primary-cta-color-60 hover:bg-primary-cta-color-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-cta-color-60"
                cancelAllOrderCancelClassName="inline-flex items-center p-2 border border-neutral-control-color-70 shadow-sm text-sm leading-4 font-medium rounded-sm text-neutral-control-layer-color-80 bg-neutral-control-color-00 hover:bg-neutral-control-color-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-cta-color-60"
              />
              <Component {...pageProps} />
            </div>
          </ErrorBoundary>
        </CoreProvider>
        {/* <Script
                    id="chatlio"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `
                            window._chatlio = window._chatlio||[];
                            !function(){ var t=document.getElementById("chatlio-widget-embed");
                            if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);
                            for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},
                            i=["configure","identify","track","show","hide","isShown","isOnline"],
                            a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));
                            var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];
                            n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,
                            n.setAttribute("data-embed-version","2.3");
                            n.setAttribute('data-widget-id','${publicRuntimeConfig.chatlioDataWidgetId}');
                            c.parentNode.insertBefore(n,c);
                            }();

                            function openChat() {
                                _chatlio.show({expanded:true});
                            }
                            `,
                    }}
                /> */}
      </CookiesProvider>
    </>
  )
}
