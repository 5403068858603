import { ActiveStarIcon, EditIcon, EmptyStarIcon } from 'assets/images/AdminButtonIcons'
import classNames from 'classnames'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface AdminButtonsPanelProps {
  isMobile?: boolean
  editLink?: string
  isPinnedShown?: boolean
  isPinned?: boolean
  isAdmin?: boolean
  onPinClick?: any
  containerClassname?: string
}

export const AdminButtonsPanel: React.FC<AdminButtonsPanelProps> = ({
  isMobile,
  editLink = '#',
  isPinnedShown,
  isPinned,
  isAdmin,
  onPinClick,
  containerClassname = 'flex flex-row gap-3',
}) => {
  const adminButtonClassNames = classNames(
    'flex flex-row rounded items-center justify-center gap-2 pointer text-sm font-metro-semibold text-neutral-control-layer-color-70 bg-neutral-control-color-0 hover:bg-neutral-control-color-10 border shadow-sm border-neutral-control-color-70',
    {
      'w-[30px] h-[30px]': isMobile,
      'px-3 py-1 w-auto': !isMobile,
    },
  )

  if (!isAdmin) {
    return <></>
  }

  return (
    <div className={containerClassname}>
      {isPinnedShown && (
        <button className={adminButtonClassNames} onClick={onPinClick}>
          {isPinned ? <ActiveStarIcon /> : <EmptyStarIcon />}
          {!isMobile &&
            (isPinned ? (
              <FormattedMessage id="page.articles.list.unpin" />
            ) : (
              <FormattedMessage id="page.articles.list.pin" />
            ))}
        </button>
      )}
      <Link href={editLink || ''}>
        <a className={adminButtonClassNames}>
          <EditIcon />
          {!isMobile && <FormattedMessage id="page.articles.list.edit" />}
        </a>
      </Link>
    </div>
  )
}
