import { SVGICON } from '@openware/opendax-web-sdk'
import React from 'react'

export const GoogleIcon: React.FC<{ isButtonEnabled: boolean }> = ({ isButtonEnabled }) => {
  return (
    <SVGICON>
      <svg
        className={`w-6 h-6 duration-200 ${
          isButtonEnabled ? 'fill-neutral-control-layer-color-10' : 'fill-neutral-control-layer-color-50'
        }`}
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.7 0C5.06723 0 0.5 4.56723 0.5 10.2C0.5 15.8328 5.06723 20.4 10.7 20.4C16.3328 20.4 20.9 15.8328 20.9 10.2C20.9 4.56723 16.3328 0 10.7 0ZM14.5022 14.4257C13.5779 15.2772 12.3165 15.7781 10.8116 15.7781C8.63268 15.7781 6.7475 14.5282 5.82996 12.7067C5.45201 11.9531 5.23571 11.1016 5.23571 10.2C5.23571 9.29839 5.45201 8.44687 5.82996 7.69326C6.7475 5.86955 8.63268 4.6196 10.8116 4.6196C12.3142 4.6196 13.5756 5.17286 14.5432 6.07219L12.9449 7.67277C12.3666 7.11951 11.6312 6.83946 10.8138 6.83946C9.36125 6.83946 8.13179 7.82076 7.69237 9.13902C7.5808 9.47371 7.51705 9.83116 7.51705 10.2C7.51705 10.5688 7.5808 10.9263 7.69237 11.261C8.13179 12.5792 9.36125 13.5605 10.8116 13.5605C11.5629 13.5605 12.2004 13.3625 12.699 13.0278C13.291 12.6316 13.6826 12.0419 13.8124 11.3429H10.8116V9.18455H16.0641C16.1301 9.55112 16.1643 9.93134 16.1643 10.3252C16.1643 12.026 15.5564 13.4535 14.5022 14.4257Z" />
      </svg>
    </SVGICON>
  )
}
