import { ChevronRightIcon } from '@heroicons/react/solid'
import { useSetMobileDevice } from '@openware/opendax-web-sdk'
import Image from 'next/image'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import { Asset } from '../../types'

interface AssetLabelBlockParams {
  asset: Asset
  /**
   * Response from server for detecting mobile device
   */
  responseIsMobile?: boolean
}

const AssetLabelBlock = ({ asset, responseIsMobile }: AssetLabelBlockParams) => {
  const isMobile = useSetMobileDevice(false, 1025)

  return (isMobile === undefined ? responseIsMobile : isMobile) ? (
    <div className="drop-shadow-sm border border-divider-color-20 rounded flex p-3 w-full min-w-[240px] max-w-[255px]">
      <div className="mr-3">
        <Image
          src={asset.logo.startsWith('http') ? asset.logo : '/images/icons/missing_small.png'}
          alt={`${asset?.name ? asset.name : asset.code.toUpperCase()} logo`}
          height={60}
          width={60}
        />
      </div>
      <div className="font-metro-semibold w-full flex flex-col">
        <div className="flex">
          <h2 className="text-text-color-100 text-xl mr-2">{asset?.name ? asset.name : asset.code.toUpperCase()}</h2>
          <span className="rounded bg-neutral-control-color-20 py-1 px-2 uppercase text-neutral-control-layer-color-90 text-sm h-fit">
            {asset.code}
          </span>
        </div>
        <Link href={`/asset/${asset.code}`}>
          <a className="group text-secondary-cta-color-60 hover:text-secondary-cta-color-80 font-metro-semibold text-sm">
            <FormattedMessage id="page.articles.view.assetPage" />
            <ChevronRightIcon className="h-5 inline-block transition hover:duration-200 group-hover:translate-x-1" />
          </a>
        </Link>
      </div>
    </div>
  ) : (
    <aside className="mr-3 mt-10 block min-w-[240px] max-w-[255px]">
      <div className="shadow-sm border border-divider-color-20 rounded relative flex flex-col items-center justify-center">
        <div className="absolute top-[-15%] translate-x-[-50%]]">
          <Image
            src={asset.logo.startsWith('http') ? asset.logo : '/images/icons/missing_small.png'}
            alt={`${asset?.name ? asset.name : asset.code.toUpperCase()} logo`}
            height={60}
            width={60}
          />
        </div>
        <div className="text-center font-metro-semibold pt-10 pb-5 px-5 border-b border-divider-color-20 w-full">
          <div className="mb-4 text-text-color-100 text-xl">{asset?.name ? asset.name : asset.code.toUpperCase()}</div>
          <span className="rounded bg-neutral-control-color-20 p-2 uppercase text-neutral-control-layer-color-90 text-sm">
            {asset.code}
          </span>
        </div>
        <div className="py-4">
          <Link href={`/asset/${asset.code}`}>
            <a className="group text-secondary-cta-color-60 hover:text-secondary-cta-color-80 font-metro-semibold text-sm">
              <FormattedMessage id="page.articles.view.assetPage" />
              <ChevronRightIcon className="h-5 inline-block transition hover:duration-200 group-hover:translate-x-1" />
            </a>
          </Link>
        </div>
      </div>
    </aside>
  )
}

export default AssetLabelBlock
