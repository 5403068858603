export const en = {
  //HEAD tag titles
  'page.tab.header.all': 'All',
  'page.tab.header.category': 'Top {category} Coins and Tokens: Price, Reviews and Market Cap',
  'page.tab.categories.description':
    'Trade {category} cryptocurrency and digital assets on the Yellow Exchange. Get access to live data on the top performing coins, along with up to date news on everything about {category}.',
  'page.tab.header.home': 'Home',
  'page.tab.header.cryptocurrencies': 'Cryptocurrencies',
  'page.tab.header.smart_contract_platform': 'Smart Contract Platform',
  'page.tab.header.stablecoin': 'Stablecoin',
  'page.tab.header.exchange_based_token': 'Exchange-based token',
  'page.tab.header.ecosystem_token': 'Ecosystem token',
  'page.tab.header.defi': 'DeFi',
  'page.tab.header.meme_tokens': 'Meme-tokens',
  'page.tab.header.gamefi': 'GameFi',
  'page.tab.header.communication_analytics': 'Communication & Analytics',
  'page.tab.header.governance_business_platform': 'Governance & Business platform',

  //Header buttons
  'header.button.early.access': 'Early Access',
  'header.button.trade': 'Trade',
  'header.button.wallet': 'Wallet',
  'header.button.new.post': 'Post an Article',
  'header.button.new.blog': 'New Blog Post',

  //Sidebar
  'page.sidebar.navigation.markets': 'Markets',
  'page.sidebar.navigation.quick exchange': 'Quick exchange',
  'page.sidebar.navigation.wallet': 'Wallet',
  'page.sidebar.navigation.separator.list': 'Yellow List',
  'page.sidebar.navigation.separator.trade': 'Trade',
  'page.sidebar.navigation.news': 'News',
  'page.sidebar.navigation.articles': 'Articles',
  'page.sidebar.navigation.history.submenu.withdrawals': 'Withdrawals',
  'page.sidebar.bottom.buttons.signin': 'Log in',
  'page.sidebar.navigation.classic': 'Classic yellow.com',

  //Home
  'page.home.title': 'Cryptocurrency and Digital Asset Prices, Reviews and Market Cap',
  'page.home.meta.description':
    'Trade cryptocurrency and digital assets on the Yellow Exchange. Get access to live data on the top performing coins, along with up to date news on everything blockchain.',

  //Settings
  'page.settings.title': 'Account Settings',
  'page.settings.meta.description':
    'Update your Yellow.com settings; connect your wallet, change your connection type, toggle dark mode, and adjust the display language.',

  //Trading
  'page.trading.title': '{pair} Spot Trading',
  'page.trading.meta.description':
    'Trade {symbol} and other assets on the Yellow Exchange. Fast and Secure App for Traders, built on top of the Yellow Network',

  //Assets
  'page.assets.title.digitalAssets': 'Digital Assets',
  'page.assets.title.assets': 'Digital Assets: From Cryptocurrencies to Meme Coins',
  'page.assets.title.stablecoin': 'Top Stablecoins by Market Cap',
  'page.assets.title.communication_analytics': 'Top Analytics, Oracles & Communication Coins by Market Cap',
  'page.assets.title.ecosystem_token': 'Top Ecosystem Coins by Market Cap',
  'page.assets.title.governance_business_platform': 'Top Governance Tokens by Market Cap',
  'page.assets.title.meme_tokens': 'Top Meme Coins by Market Cap',
  'page.assets.title.smart_contract_platform': 'Top Smart Contract Platforms by Market Cap',
  'page.assets.title.gamefi': 'Top Gaming Coins by Market Cap',
  'page.assets.title.defi': 'Top DeFi Tokens by Market Cap',
  'page.assets.title.cryptocurrencies': 'Top Cryptocurrencies by Market Cap',
  'page.assets.title.exchange_based_token': 'Top Exchange-based Tokens by Market Cap',
  'page.assets.title.topAssets': 'Assets by Market Cap',
  'page.assets.title.topAssets.all': 'Top Assets by Market Cap',
  'page.assets.description':
    "Discover all digital assets on Yellow.Finance. Cryptocurrencies, stablecoins, meme-tokens – prices,  charts, market cap, and trading volume. Get insights of tomorrow's GEMs, trending coins, top crypto gainers and losers",
  'page.assets.meta.description':
    'View all the digital assets listed on Yellow exchange. From cryptocurrencies and ecosystem tokens, to meme coins and DeFi, you’ll get insights into current prices, market cap, and trading volume.',
  'page.assets.meta.description.stablecoin':
    'Get live prices for Stablecoins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.communication_analytics':
    'Get live prices for Analytics, Oracles & Communication coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.ecosystem_token':
    'Get live prices for Ecosystem coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.governance_business_platform':
    'Get live prices for Governance tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.meme_tokens':
    'Get live prices for Meme coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.smart_contract_platform':
    'Get live prices for Smart Contract Platforms, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.gamefi':
    'Get live prices for Gaming coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.defi':
    'Get live prices for DeFi tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.cryptocurrencies':
    'Get live prices for Cryptocurrencies, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.exchange_based_token':
    'Get live prices for Exchange-based tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.description.more': 'More',
  'page.assets.description.less': 'Less',
  'page.assets.table.number': '#',
  'page.assets.table.assets': 'Asset',
  'page.assets.table.currentPrice': 'Current Price',
  'page.assets.table.price': 'Price',
  'page.assets.table.1hour.change': 'Change 1h',
  'page.assets.table.1day.change': 'Change 1D',
  'page.assets.table.1week.change': 'Change 1W',
  'page.assets.table.24hours.change': '24h Change',
  'page.assets.table.change-1h/24h/7d': 'Change 1h/24h/7d',
  'page.assets.table.volume': '24h Volume',
  'page.assets.table.volume-24': 'Volume 24h',
  'page.assets.table.totalVolume': 'Total Volume',
  'page.assets.table.supply': 'Circulating Supply',
  'page.assets.table.marketCap': 'Market Cap',

  //404 Page
  'page.404.title': 'Page Not Found',
  'page.404.description':
    'There’s something strange in the neighborhood! We don’t know where the page you’re looking for is. Try to reload, or check the link you were following.',
  'page.404.assets.link': 'All Assets',

  //505 Page
  'page.500.title': '500',
  'page.500.description': 'Internal server error',
  'page.500.home.link': 'Go back home',
  'page.500.refresh': 'Refresh',

  //Client error page
  'page.400.title': 'Ooops! Something went wrong.',
  'page.400.description':
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  'page.400.refresh': 'Refresh',

  //Footer
  'footer.navigation.legal': 'LEGAL',
  'footer.navigation.legal.submenu.terms': 'Terms of service',
  'footer.navigation.legal.submenu.privacy': 'Privacy policy',
  'footer.navigation.legal.submenu.cookie': 'Cookie policy',
  'footer.navigation.exchange': 'Exchange',
  'footer.navigation.exchange.submenu.support': 'Support',
  'footer.navigation.exchange.submenu.tokens': 'List your token',
  'footer.navigation.exchange.submenu.yellowToken': 'About YELLOW token',
  'footer.navigation.company': 'Company',
  'footer.navigation.company.submenu.incubator': 'Incubator',
  'footer.navigation.company.submenu.coworking': 'Coworking',
  'footer.navigation.company.submenu.foundation': 'Foundation',
  'footer.navigation.socials': 'Socials',

  'footer.poweredBy.kickstart': 'Kickstart your crypto business with Yellow Network',

  //Landing
  'page.landing.banner.title': 'Become a part of Yellow Network',
  'page.landing.banner.description':
    'An overlay network to trade any digital asset across any blockchain at ultra-high-speed.',
  'page.landing.banner.button': 'Join',
  'page.landing.articles.title': 'Latest Updates',
  'page.landing.articles.more': 'Read article',

  //Breadcrumbs
  'breadcrumbs.digital-assets': 'Digital Assets',

  //TokenMetric
  'page.asset.tokenmetric.mobile.key': 'Key Metrics',
  'page.asset.tokenmetric.price': '{asset} Price',
  'page.asset.tokenmetric.change.percentage.day': '%',
  'page.asset.tokenmetric.change-1w': 'Change 1w',
  'page.asset.tokenmetric.rank': 'Rank #{rank}',

  //TokenMarkets
  'page.asset.tokenmarkets.available': 'Available market pairs for {asset}',
  'page.asset.tokenmarkets.24h-volume': 'Volume 24h',
  'page.asset.tokenmarkets.allMarkets': 'All Markets',
  'page.asset.tokenmarkets.noPairs': 'No available market pairs',

  //TokenSocialLinks
  'page.asset.tokensocial.info': 'info',
  'page.asset.tokensocial.lastUpdate': 'Last Update',
  'page.asset.tokensocial.showMore': 'Show More',

  //TokenContracts
  'page.asset.tokencontract.contracts': 'Contracts',
  'page.asset.tokencontract.showMore': 'Show More',
  'page.asset.tokencontract.tags': 'Tags',
  'page.asset.tokencontract.categories': 'Categories',

  //TokenDescription
  'page.asset.tokendescription.button.update': 'Update & Publish',
  'page.asset.tokendescription.button.showmore': 'Read More',

  //TokenChart
  'page.asset.tokenchart.title': 'Historical prices (in {currency})',
  'page.asset.tokenchart.price': 'Price',
  'page.asset.tokenchart.volume': 'Volume',
  'page.asset.tokenchart.period.24h': '24H',
  'page.asset.tokenchart.period.7d': '7D',
  'page.asset.tokenchart.period.14d': '14D',
  'page.asset.tokenchart.period.1m': '1M',
  'page.asset.tokenchart.period.3m': '3M',

  //TooltipButtonLabels
  'page.asset.tooltip.button.readmore': 'Read More >',

  //TooltipPrice
  'page.asset.tooltip.price.title': 'Market Price',
  'page.asset.tooltip.price.content':
    'Market price is globally aggregated, volume-weighted average price of the cryptoasset, from where it’s listed & traded.',

  //TooltipVolume
  'page.asset.tooltip.volume.title': '24 hours volume',
  'page.asset.tooltip.volume.content': 'A measure of how much of a cryptocurrency was traded in the last 24 hours.',

  //TooltipMarketCap
  'page.asset.tooltip.marketCap.title': 'What is market cap?',
  'page.asset.tooltip.marketCap.content-1':
    "The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.",
  'page.asset.tooltip.marketCap.content-2': 'Market Cap = Current Price x Circulating Supply',

  //TooltipSupply
  'page.asset.tooltip.supply.title': 'What is circulating supply?',
  'page.asset.tooltip.supplu.content':
    'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.',

  //TooltipCopyContract
  'page.asset.tooltip.copy.contract': 'Click to copy',
  'page.asset.tooltip.copied': 'Copied!',

  //Chart
  'page.asset.chart.title': 'Historical daily prices (in USDT)',

  //MoreArticles
  'page.asset.moreArticles.title': 'More Articles About {asset}',
  'page.asset.moreArticles.link': 'Show All Articles',
  'page.asset.moreArticles.link.allNews': 'Show All Blog Posts',

  // Pagination
  'page.assets.pagination.of': 'of',
  'page.assets.pagination.next': 'Next',
  'page.assets.pagination.previous': 'Previous',
  'page.assets.paginanation.label': 'Showing {from} to {to} of {total} results',

  // Landing
  // Markets Widget
  'page.landing.markets.title': 'Markets',

  'page.landing.markets.tabs.mostActive': 'Most Active',
  'page.landing.markets.tabs.gainers': 'Gainers',
  'page.landing.markets.tabs.losers': 'Losers',

  'page.landing.markets.table.headers.symbol': 'Symbol',
  'page.landing.markets.table.headers.volume': '24h Volume',
  'page.landing.markets.table.headers.change': '24h Change',
  'page.landing.markets.table.headers.lastPrice': 'Last Price',

  //Articles
  'page.articles.title': 'Asset Reviews: In-Depth Articles and Token Overviews',
  'page.articles.breadcrumb': 'Articles',
  'page.articles.meta.description':
    "Learn more about the projects you're interested in and discover in-depth research articles, token overviews, price predictions, and more in our Asset Reviews.",
  'page.articles.empty.title': 'Crypto Reviews & News',
  'page.articles.empty.message': 'Oh, you’re unlucky to see this empty. There will be amazing content soon!',
  'page.articles.empty.new': 'New Post',
  'page.articles.pinned.title': 'Replace pinned',
  'page.articles.pinned.message': 'Are you sure you want to change pinned article?',
  'page.articles.pinned.replace': 'Replace',
  'page.articles.pinned.cancel': 'Cancel',
  'page.articles.header.article.title': 'Asset Articles',
  'page.articles.header.article.description':
    'Curated knowledge base for crypto: Find well-written research articles, token overviews, price predictions, market trends, technology reviews and updates. Read and share your thoughts, too!',
  'page.articles.header.article.button': 'Post an Article',
  'page.articles.header.selectedArticle.title': '{token} Articles',
  'page.articles.header.selectedArticle.description':
    'What users of Yellow.com think about {token}: token overview, price predictions, market trends, technology review and updates. Read and share your thoughts, too!',
  'page.articles.header.selectedArticle.button': 'Post an Article',

  // Blog
  'page.blogs.title': 'News: Stay Informed on the Latest Crypto Updates',
  'page.blogs.meta.description':
    "Stay informed on the latest news from the crypto and blockchain space, from project specific updates and price analysis, to what's new here at Yellow.",
  'page.blogs.header.title': 'Yellow.com News',
  'page.blogs.header.description':
    'See what’s new with yellow.com and crypto-industry here. Company-editorial blog for the hottest content in the world of Yellow.com.',
  'page.blogs.header.button.label': 'New Blog Post',
  'page.blogs.create.title': 'New Blog Post',
  'page.blogs.edit.title': 'Edit Blog Post',
  'page.blogs.view.moreBlogPosts': 'More Blog Posts',

  // Create & edit article
  'page.tab.header.articles.create': 'Create Article',
  'page.tab.header.articles.edit': 'Edit Article',
  'page.articles.create.title': 'New Post',
  'page.articles.edit.title': 'Edit Post',
  'page.articles.create.dropdown.placeholder': 'Select the asset',
  'page.articles.create.dropdown.search.placeholder': 'Search',
  'page.articles.create.dropdown.noData': 'Nothing found',
  'page.articles.create.input.title.placeholder': 'Title',
  'page.articles.create.button.saveAsDraft': 'Save as draft',
  'page.articles.create.button.publish': 'Publish',
  'page.articles.create.button.publishUpdate': 'Update & Publish',
  'page.articles.create.button.delete': 'Delete',
  'page.articles.create.button.saving': 'Saving...',

  // Article view page
  'page.articles.view.assetPage': 'Asset page',
  'page.articles.view.showAllNews': 'Show All Blog Posts',
  'page.articles.view.showAllArticles': 'Show All Articles',

  // Empty State
  'page.asset.emptyState.label': 'No articles yet',
  'page.asset.emptyState.post': 'Post an Article',
  'page.assets.table.emptyState.label': 'No data to show',

  // Badge
  'page.asset.badge.featured.article': 'Featured Article',

  // Admin Buttons
  'page.articles.list.pin': 'Pin',
  'page.articles.list.unpin': 'Unpin from asset',
  'page.articles.list.edit': 'Edit',

  // Confirmation Modal
  'modal.confirmation.title.publish': 'Publish Article',
  'modal.confirmation.button.publish': 'Publish',
  'modal.confirmation.body.publish': 'Are you sure you want to publish this article? It will be accessed by any user.',
  'modal.confirmation.title.draft': 'Save Draft Article',
  'modal.confirmation.button.draft': 'Save',
  'modal.confirmation.body.draft': 'Are you sure you want to save this artircle as a draft review?',
  'modal.confirmation.title.delete': 'Delete Article',
  'modal.confirmation.button.delete': 'Delete',
  'modal.confirmation.body.delete':
    'Are you sure you want to delete this article? This will delete it permanently. To hide it from public, you can save as draft.',
  'modal.confirmation.title.pin': 'Pin Article',
  'modal.confirmation.button.pin': 'Pin',
  'modal.confirmation.body.pin': 'Are you sure you want to pin this article to the Asset Page?',
  'modal.confirmation.title.unpin': 'Unpin Article',
  'modal.confirmation.button.unpin': 'Unpin',
  'modal.confirmation.body.unpin':
    'Are you sure you want to unpin this article from the Asset Page? It will have minimized view on the page.',
  'modal.confirmation.title.replace': 'Replace Pinned Article',
  'modal.confirmation.button.replace': 'Replace',
  'modal.confirmation.body.replace':
    'Are you sure you want to pin this article to the Asset Page? Current pinned article will have minimized view on the page.',
  'modal.confirmation.blog.title.publish': 'Publish Blog',
  'modal.confirmation.blog.body.publish':
    'Are you sure you want to publish this blog? It will be accessed by any user.',
  'modal.confirmation.blog.title.delete': 'Delete Blog',
  'modal.confirmation.blog.body.delete': 'Are you sure you want to delete this blog? This will delete it permanently',

  // Disclaimers
  'modal.disclaimer.testnet.title': 'Beta is working on Polygon Mainnet',
  'modal.disclaimer.testnet.description':
    'Yellow Exchange is currently in Beta. Deposit & Withdraw are supported only with Polygon Mainnet, other features are fully accessible. We are happy to hear your feedback.',
  'modal.disclaimer.testnet.button.ok': 'Okay, I’ll use Polygon',
  'modal.disclaimer.cookie.title': 'Cookies!',
  'modal.disclaimer.cookie.description':
    'We use cookies for login, enhancement of your experience, analysis of our traffic, and for security and marketing purposes. By using our website you agree to our use of cookies.  For more information about cookies, see our',
  'modal.disclaimer.cookie.policy': 'Cookie Policy',
  'modal.disclaimer.cookie.button.ok': 'I am okay with all cookies',
  'modal.disclaimer.cookie.button.cancel': 'Accept only essential cookies',
  'modal.disclaimer.classic.button.close': 'Close',
  'modal.disclaimer.classic.description':
    'Yellow Exchange is now switching to OpenDAX v4 to connect to Yellow Network in future',
  'modal.disclaimer.classic.learn': 'learn more.',
  'modal.disclaimer.classic.continue': 'You can still access stable older version here:',

  // Auth Wizard
  'modal.auth.email.hint': 'Enter your email',
  'modal.auth.email.label.invalid': 'The email is invalid',
  'modal.auth.email.label.sign.up': 'Sign up',
  'modal.auth.confirm.label': 'I have read and I agree with the',
  'modal.auth.confirm.link.terms': 'Terms of Service',
  'modal.auth.title.sign.up': 'Sign in',

  //Wallet Modal
  'modal.wallet.confirm.label': 'I have read and I agree with the ',
  'modal.wallet.confirm.link.terms': 'Terms of Service',
}
