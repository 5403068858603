import { useAppSelector } from '@openware/opendax-web-sdk'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const withRole = (
  WrappedComponent: any,
  redirectPath: string = '/',
  allowedRoles: string[] = ['superadmin', 'writer'],
) => {
  return (props: any) => {
    const router = useRouter()
    const userLoading = useAppSelector(state => state.user.userLoading)
    const userRole = useAppSelector(state => state.user.user.role)
    const isAdminWriter = allowedRoles.includes(userRole)

    useEffect(() => {
      !userLoading && !isAdminWriter && router.replace(redirectPath)
    }, [isAdminWriter])

    if (isAdminWriter) {
      return <WrappedComponent {...props} />
    }

    return null
  }
}
