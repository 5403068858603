import { Decimal } from '@openware/opendax-web-sdk'
import { removeTrailingFloatZero } from './removeTrailingFloatZero'

export const renderPrice = (price: number) => {
  return +price < 0.01
    ? removeTrailingFloatZero(Decimal.format(String(price), 8, ','))
    : +price < 1
    ? removeTrailingFloatZero(Decimal.format(String(price), 6, ','))
    : removeTrailingFloatZero(Decimal.format(String(price), 2, ','))
}
