export const ru = {
  //HEAD tag titles,
  'page.tab.header.all': 'Все',
  'page.tab.header.category': 'Лучшие Монеты и Токены {category}: Цена, Отзывы и Рыночная Капитализация',
  'page.tab.categories.description':
    'Trade {category} cryptocurrency and digital assets on the Yellow Exchange. Get access to live data on the top performing coins, along with up to date news on everything about {category}.',
  'page.tab.header.home': 'Главный',
  'page.tab.header.cryptocurrencies': 'Криптовалюты',
  'page.tab.header.smart_contract_platform': 'Smart Contract Platform',
  'page.tab.header.stablecoin': 'Stablecoin',
  'page.tab.header.exchange_based_token': 'Exchange-based token',
  'page.tab.header.ecosystem_token': 'Ecosystem token',
  'page.tab.header.defi': 'DeFi',
  'page.tab.header.meme_tokens': 'Meme-tokens',
  'page.tab.header.gamefi': 'GameFi',
  'page.tab.header.communication_analytics': 'Communication & Analytics',
  'page.tab.header.governance_business_platform': 'Governance & Business platform',

  // Home
  'page.home.title': 'Цены на криптовалюту и цифровые активы, обзоры и рыночная капитализация',
  'page.home.meta.description':
    'Trade cryptocurrency and digital assets on the Yellow Exchange. Get access to live data on the top performing coins, along with up to date news on everything blockchain.',

  //Header buttons
  'header.button.early.access': 'Ранний доступ',
  'header.button.trade': 'Торговля',
  'header.button.wallet': 'Кошелек',
  'header.button.new.post': 'Создать Пост',
  'header.button.new.blog': 'Создать новостной пост',

  //Sidebar
  'page.sidebar.navigation.markets': 'Рынки',
  'page.sidebar.navigation.quick exchange': 'Быстрый обмен',
  'page.sidebar.navigation.wallet': 'Кошельки',
  'page.sidebar.navigation.separator.list': 'Список Yellow',
  'page.sidebar.navigation.separator.trade': 'Торговля',
  'page.sidebar.navigation.news': 'Новости',
  'page.sidebar.navigation.articles': 'Статьи',
  'page.sidebar.navigation.history.submenu.withdrawals': 'Выводы',
  'page.sidebar.navigation.classic': 'Classic yellow.com',

  //Trading
  'page.trading.title': '{pair} Спотовая Рынок',
  'page.trading.meta.description':
    'Trade {symbol} and other assets on the Yellow Exchange. Fast and Secure App for Traders, built on top of the Yellow Network',

  //Settings
  'page.settings.title': 'Настройки Учетной Записи',
  'page.settings.meta.description':
    'Update your Yellow.com settings; connect your wallet, change your connection type, toggle dark mode, and adjust the display language.',

  //Assets
  'page.assets.title.digitalAssets': 'Цифровые активы',
  'page.assets.title.topAssets': 'активы за рыночной капитализацией',
  'page.assets.title.topAssets.all': 'Лучшие активы за рыночной капитализацией',
  'page.assets.title.assets': 'Цифровые активы: От Cryptocurrencies до Meme Coins',
  'page.assets.title.stablecoin': 'Лучшие Stablecoins по Рыночной Капитализации',
  'page.assets.title.communication_analytics':
    'Лучшие Analytics, Oracles & Communication Coins по Рыночной Капитализации',
  'page.assets.title.ecosystem_token': 'Лучшие Ecosystem Coins по Рыночной Капитализации',
  'page.assets.title.governance_business_platform': 'Лучшие Governance Токены по Рыночной Капитализации',
  'page.assets.title.meme_tokens': 'Лучшие Meme Coins по Рыночной Капитализации',
  'page.assets.title.smart_contract_platform': 'Лучшие Smart Contract Platforms по Рыночной Капитализации',
  'page.assets.title.gamefi': 'Лучшие Gaming Coins по Рыночной Капитализации',
  'page.assets.title.defi': 'Лучшие DeFi Токены по Рыночной Капитализации',
  'page.assets.title.cryptocurrencies': 'Лучшие Cryptocurrencies по Рыночной Капитализации',
  'page.assets.title.exchange_based_token': 'Лучшие Exchange-based Токены по Рыночной Капитализации',
  'page.assets.description':
    "Discover all digital assets on Yellow.Finance. Cryptocurrencies, stablecoins, meme-tokens – prices,  charts, market cap, and trading volume. Get insights of tomorrow's GEMs, trending coins, top crypto gainers and losers",
  'page.assets.meta.description':
    'View all the digital assets listed on Yellow exchange. From cryptocurrencies and ecosystem tokens, to meme coins and DeFi, you’ll get insights into current prices, market cap, and trading volume.',
  'page.assets.meta.description.stablecoin':
    'Get live prices for Stablecoins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.communication_analytics':
    'Get live prices for Analytics, Oracles & Communication coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.ecosystem_token':
    'Get live prices for Ecosystem coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.governance_business_platform':
    'Get live prices for Governance tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.meme_tokens':
    'Get live prices for Meme coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.smart_contract_platform':
    'Get live prices for Smart Contract Platforms, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.gamefi':
    'Get live prices for Gaming coins, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.defi':
    'Get live prices for DeFi tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.cryptocurrencies':
    'Get live prices for Cryptocurrencies, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.meta.description.exchange_based_token':
    'Get live prices for Exchange-based tokens, along with 24 hour volume, market cap, and circulating supply; listed by market capitalization.',
  'page.assets.description.more': 'Більше',
  'page.assets.description.less': 'Менше',
  'page.assets.table.number': '#',
  'page.assets.table.assets': 'Активы',
  'page.assets.table.currentPrice': 'Текущая цена',
  'page.assets.table.price': 'Цена',
  'page.assets.table.1hour.change': 'Изменения 1ч',
  'page.assets.table.1day.change': 'Изменения 1Д',
  'page.assets.table.1week.change': 'Изменения 1Н',
  'page.assets.table.24hours.change': '24ч Изменения',
  'page.assets.table.change-1h/24h/7d': 'Изменения 1ч/24ч/7д',
  'page.assets.table.volume': '24ч Объем ',
  'page.assets.table.volume-24': 'Объем 24ч',
  'page.assets.table.totalVolume': 'Общий объем',
  'page.assets.table.supply': 'Циркулирующий объем',
  'page.assets.table.marketCap': 'Рыночная капитализация',

  //404 Page
  'page.404.title': 'Страница не найдена',
  'page.404.description':
    'There’s something strange in the neighborhood! We don’t know where the page you’re looking for is. Try to reload, or check the link you were following.',
  'page.404.assets.link': 'Все активы',

  //505 Page
  'page.500.title': '500',
  'page.500.description': 'Internal server error',
  'page.500.home.link': 'Возвращайся домой',
  'page.500.refresh': 'Обновить',

  //Client error page
  'page.400.title': 'Упс! Что-то пошло не так.',
  'page.400.description':
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  'page.400.refresh': 'Обновить',

  //Footer
  'footer.navigation.legal': 'LEGAL',
  'footer.navigation.legal.submenu.terms': 'Условия эксплуатации',
  'footer.navigation.legal.submenu.privacy': 'Политика конфиденциальности',
  'footer.navigation.legal.submenu.cookie': 'Cookie',
  'footer.navigation.exchange': 'Обмен',
  'footer.navigation.exchange.submenu.support': 'Поддержка',
  'footer.navigation.exchange.submenu.tokens': 'Перечислите свой токен',
  'footer.navigation.exchange.submenu.yellowToken': 'О YELLOW токене',
  'footer.navigation.company': 'Компания',
  'footer.navigation.company.submenu.incubator': 'инкубатор',
  'footer.navigation.company.submenu.coworking': 'Коворкинг',
  'footer.navigation.company.submenu.foundation': 'Foundation',
  'footer.navigation.socials': 'Социальные сети',

  'footer.poweredBy.kickstart': 'Начните свой криптобизнес с Yellow Network',

  //Breadcrumbs
  'breadcrumbs.digital-assets': 'Цифровые активы',

  //TokenMetric
  'page.asset.tokenmetric.mobile.key': 'Ключевые метрики',
  'page.asset.tokenmetric.price': 'Цена {asset}',
  'page.asset.tokenmetric.change.percentage.day': '% Д',
  'page.asset.tokenmetric.change-1w': 'Изменение 1н',
  'page.asset.tokenmetric.rank': 'Ранг №{rank}',

  //TokenMarkets
  'page.asset.tokenmarkets.available': 'Доступные пары для торговли {asset}',
  'page.asset.tokenmarkets.24h-volume': 'Объем 24ч',
  'page.asset.tokenmarkets.allMarkets': 'Все Рынки',
  'page.asset.tokenmarkets.noPairs': 'Нету доступных для торговли пар',

  //Landing
  'page.landing.banner.title': 'Покупай или продавай в несколько кликов',
  'page.landing.banner.description':
    'Быстрый и безопасный способ купить или продать любой доступный на рынке цифровой актив.',
  'page.landing.banner.button': 'Начать торговать',
  'page.landing.articles.title': 'Последние обновления',
  'page.landing.articles.more': 'Читать статью',

  //TokenSocialLinks
  'page.asset.tokensocial.info': 'инфо',
  'page.asset.tokensocial.lastUpdate': 'Посл. обновл.',
  'page.asset.tokensocial.showMore': 'Показать больше',

  //TokenContracts
  'page.asset.tokencontract.contracts': 'Контракты',
  'page.asset.tokencontract.showMore': 'Показать больше',
  'page.asset.tokencontract.tags': 'Tags',
  'page.asset.tokencontract.categories': 'Категории',

  //TokenDescription
  'page.asset.tokendescription.button.update': 'Обновить и Опубликовать',
  'page.asset.tokendescription.button.showmore': 'Показать больше',

  //TokenChart
  'page.asset.tokenchart.title': 'Исторические цены (в {currency})',
  'page.asset.tokenchart.price': 'Цена',
  'page.asset.tokenchart.volume': 'Объем',
  'page.asset.tokenchart.period.24h': '24H',
  'page.asset.tokenchart.period.7d': '7D',
  'page.asset.tokenchart.period.14d': '14D',
  'page.asset.tokenchart.period.1m': '1M',
  'page.asset.tokenchart.period.3m': '3M',

  //TooltipButtonLabels
  'page.asset.tooltip.button.readmore': 'Больше >',

  //TooltipPrice
  'page.asset.tooltip.price.title': 'Market Price',
  'page.asset.tooltip.price.content':
    'Market price is globally aggregated, volume-weighted average price of the cryptoasset, from where it’s listed & traded.',

  //TooltipVolume
  'page.asset.tooltip.volume.title': 'Объем за 24 часа',
  'page.asset.tooltip.volume.content': 'Мера того, как криптовалюта торговалась последние 24 часа',

  //TooltipMarketCap
  'page.asset.tooltip.marketCap.title': 'Что такое рыночная капитализация?',
  'page.asset.tooltip.marketCap.content-1':
    'Общая риночная стоимость циркулирующего объема криптовалюты. Аналогично free-float капитализации на рынке акций.',
  'page.asset.tooltip.marketCap.content-2': 'Рыночная капитализация = Текущая цена x Циркулирующий объем',

  //TooltipSupply
  'page.asset.tooltip.supply.title': 'Что такое циркулирующий объем?',
  'page.asset.tooltip.supplu.content':
    'Объем монет, что циркулируется на рынке и находится в руках публичных инвесторов. Аналогично flowing shares на рынке акций.',

  //Chart
  'page.asset.chart.title': 'График ежедневных цен (в USDT)',

  //MoreArticles
  'page.asset.moreArticles.title': 'More Articles About {asset}',
  'page.asset.moreArticles.link': 'Show All Articles',
  'page.asset.moreArticles.link.allNews': 'Show All News',

  // Pagination
  'page.assets.pagination.of': 'с',
  'page.assets.pagination.next': 'Следующая',
  'page.assets.pagination.previous': 'Предыдущая',
  'page.assets.paginanation.label': 'Показано от {from} до {to} с {total} результатов',

  // Landing
  // Markets Widget
  'page.landing.markets.title': 'Рынки',

  'page.landing.markets.tabs.mostActive': 'Наиболее активные',
  'page.landing.markets.tabs.gainers': 'Гейнеры',
  'page.landing.markets.tabs.losers': 'Неудачники',

  'page.landing.markets.table.headers.symbol': 'Символ',
  'page.landing.markets.table.headers.volume': 'Объем за 24ч',
  'page.landing.markets.table.headers.change': 'Измeнение за 24ч',
  'page.landing.markets.table.headers.lastPrice': 'Последняя цена',

  //Articles
  'page.articles.title': 'Обзоры Активов: Подробные Статьи и Обзоры Токенов',
  'page.articles.meta.description':
    "Learn more about the projects you're interested in and discover in-depth research articles, token overviews, price predictions, and more in our Asset Reviews.",
  'page.articles.empty.title': 'Crypto Reviews & News',
  'page.articles.empty.message': 'Oh, you’re unlucky to see this empty. There will be amazing content soon!',
  'page.articles.empty.new': 'New Post',
  'page.articles.pinned.title': 'Replace pinned',
  'page.articles.pinned.message': 'Are you sure you want to change pinned article?',
  'page.articles.pinned.replace': 'Replace',
  'page.articles.pinned.cancel': 'Cancel',
  'page.articles.header.article.title': 'Asset Articles',
  'page.articles.header.article.description':
    'Curated knowledge base for crypto: Find well-written research articles, token overviews, price predictions, market trends, technology reviews and updates. Read and share your thoughts, too!',
  'page.articles.header.article.button': 'Post an Article',
  'page.articles.header.selectedArticle.title': 'Обзор {token}',
  'page.articles.header.selectedArticle.description':
    'What users of Yellow Finance think about {token}: token overview, price predictions, market trends, technology review and updates. Read and share your thoughts, too!',
  'page.articles.header.selectedArticle.button': 'Создать обзор',

  // Blog
  'page.blogs.title': 'Новости: Будьте в Курсе Последних Обновлений Crypto',
  'page.blogs.meta.description':
    "Stay informed on the latest news from the crypto and blockchain space, from project specific updates and price analysis, to what's new here at Yellow.",
  'page.blogs.header.button.label': 'Создать новый пост',
  'page.blogs.create.title': 'Новый пост в блоге',
  'page.blogs.edit.title': 'Редактировать сообщение в блоге',
  'page.blogs.view.moreBlogPosts': 'Другие статьи',

  // Create & edit article
  'page.tab.header.articles.create': 'Создать статью',
  'page.tab.header.articles.edit': 'Изменить статью',
  'page.articles.create.title': 'Новый пост',
  'page.articles.edit.title': 'Редактировать пост',
  'page.articles.create.dropdown.placeholder': 'Выберете валюту',
  'page.articles.create.dropdown.search.placeholder': 'Поиск',
  'page.articles.create.dropdown.noData': 'Ничего не найдено',
  'page.articles.create.input.title.placeholder': 'Заголовок',
  'page.articles.create.button.saveAsDraft': 'Сохранить черновик',
  'page.articles.create.button.publish': 'Опубликовать',
  'page.articles.create.button.publishUpdate': 'Обновить и опубликовать',
  'page.articles.create.button.delete': 'Удалить',
  'page.articles.create.button.saving': 'Сохранение...',

  // Article view page
  'page.articles.view.assetPage': 'Страница активов',
  'page.articles.view.showAllNews': 'Показать все новости',
  'page.articles.view.showAllArticles': 'Показать все обзоры',

  // Empty State
  'page.asset.emptyState.label': 'Обзоры ещё отсутствуют',
  'page.asset.emptyState.post': 'Создать обзор',
  'page.assets.table.emptyState.label': 'Данные отсутствуют',

  // Badge
  'page.asset.badge.featured.article': 'Избранная статья',

  // Admin Buttons
  'page.articles.list.pin': 'Закрепить',
  'page.articles.list.unpin': 'Открепить',
  'page.articles.list.edit': 'Править',

  // Confirmation Modal
  'modal.confirmation.title.publish': 'Publish Article',
  'modal.confirmation.button.publish': 'Publish',
  'modal.confirmation.body.publish': 'Are you sure you want to publish this article? It will be accessed by any user.',
  'modal.confirmation.title.draft': 'Save Draft Article',
  'modal.confirmation.button.draft': 'Save',
  'modal.confirmation.body.draft': 'Are you sure you want to save this artircle as a draft review?',
  'modal.confirmation.title.delete': 'Delete Article',
  'modal.confirmation.button.delete': 'Delete',
  'modal.confirmation.body.delete':
    'Are you sure you want to delete this article? This will delete it permanently. To hide it from public, you can save as draft.',
  'modal.confirmation.title.pin': 'Pin Article',
  'modal.confirmation.button.pin': 'Pin',
  'modal.confirmation.body.pin': 'Are you sure you want to pin this article to the Asset Page?',
  'modal.confirmation.title.unpin': 'Unpin Article',
  'modal.confirmation.button.unpin': 'Unpin',
  'modal.confirmation.body.unpin':
    'Are you sure you want to unpin this article from the Asset Page? It will have minimized view on the page.',
  'modal.confirmation.title.replace': 'Replace Pinned Article',
  'modal.confirmation.button.replace': 'Replace',
  'modal.confirmation.body.replace':
    'Are you sure you want to pin this article to the Asset Page? Current pinned article will have minimized view on the page.',
  'modal.confirmation.blog.title.publish': 'Publish Blog',
  'modal.confirmation.blog.body.publish':
    'Are you sure you want to publish this blog? It will be accessed by any user.',
  'modal.confirmation.blog.title.delete': 'Delete Blog',
  'modal.confirmation.blog.body.delete': 'Are you sure you want to delete this blog? This will delete it permanently.',

  // Disclaimers
  'modal.disclaimer.testnet.title': 'Beta is working on Polygon Mainnet',
  'modal.disclaimer.testnet.description':
    'Yellow Exchange is currently in Beta. Deposit & Withdraw are supported only with Polygon Mainnet, other features are fully accessible. We are happy to hear your feedback.',
  'modal.disclaimer.testnet.button.ok': 'Okay, I’ll use Polygon',
  'modal.disclaimer.cookie.title': 'Cookies!',
  'modal.disclaimer.cookie.description':
    'We use cookies for login, enhancement of your experience, analysis of our traffic, and for security and marketing purposes. By using our website you agree to our use of cookies.  For more information about cookies, see our',
  'modal.disclaimer.cookie.policy': 'Cookie Policy',
  'modal.disclaimer.cookie.button.ok': 'I am okay with all cookies',
  'modal.disclaimer.cookie.button.cancel': 'Accept only essential cookies',
  'modal.disclaimer.classic.button.close': 'Закрыть',
  'modal.disclaimer.classic.description':
    'Yellow Exchange теперь переходит на OpenDAX v4 для подключения к Yellow Network в будущем',
  'modal.disclaimer.classic.learn': 'узнать больше.',
  'modal.disclaimer.classic.continue': 'Вы все еще можете получить доступ к стабильной старой версии здесь:',

  //Wallet Modal
  'modal.wallet.confirm.label': 'Я прочитал и согласен с ',
  'modal.wallet.confirm.link.terms': 'Условия Использования',

  'modal.auth.confirm.label': 'Я прочитал и согласен с',
  'modal.auth.confirm.link.terms': 'Условия Использования',
}
