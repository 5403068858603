import { createClient } from '@supabase/supabase-js'
import { getPublicConfig } from 'configs/app'

export const getSupabasePublicClient = () => {
  const publicRuntimeConfig = getPublicConfig()
  const supabasePublic = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL || publicRuntimeConfig.supabaseURL,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || publicRuntimeConfig.supabaseAnonKey,
    {
      schema: 'yellow',
    },
  )

  return supabasePublic
}
