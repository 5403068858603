import getConfig from 'next/config'

export interface PublicConfig {
  supabaseURL: string
  supabaseAnonKey: string
  supabaseServiceKey: string
  jwtPublicKey: any
  authToken: string | undefined
  influxHost: string | undefined
  influxPort: string | undefined
  influxDatabase: string | undefined
  pinoLogLevel: string | undefined
  nodeEnv: 'development' | 'production' | 'test'
  rootUrl: string | undefined
  publicGitCommitSha: any | undefined
  gitCommitSha: any
  chatlioDataWidgetId: string | undefined
}

let publicConfig: undefined | PublicConfig

export const setPublicConfig = (config: PublicConfig) => (publicConfig = config)

export const getPublicConfig = (): PublicConfig => {
  if (publicConfig !== undefined) {
    return publicConfig
  }

  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

  publicConfig = { ...serverRuntimeConfig, ...publicRuntimeConfig } as PublicConfig

  return publicConfig
}
