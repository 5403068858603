import { Icons } from 'helpers/icons'
import { FormattedMessage } from 'react-intl'

const FOOTER_SOCIALS = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/Yellow',
    icon: () => <Icons name="twitter" />,
    target: '_blank',
  },
  {
    name: 'Telegram',
    href: 'https://t.me/yellow_announcements',
    icon: () => <Icons name="telegram" />,
    target: '_blank',
  },
  {
    name: 'Youtube',
    href: 'https://www.youtube.com/channel/UC2u2FXKKyIFsNBr_MlpCMfA/',
    icon: () => <Icons name="youtube" />,
    target: '_blank',
  },
  {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/company/yellow-com/',
    icon: () => <Icons name="linkedin" />,
    target: '_blank',
  },
  {
    name: 'Discord',
    href: 'https://discord.com/invite/DKBjCg6rmT',
    icon: () => <Icons name="discord" />,
    target: '_blank',
  },
  {
    name: 'Medium',
    href: 'https://medium.com/yellow-blog',
    icon: () => <Icons name="medium" />,
    target: '_blank',
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/yellowisthenewblock/',
    icon: () => <Icons name="facebook" />,
    target: '_blank',
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/YellowDeFi/',
    icon: () => <Icons name="reddit" />,
    target: '_blank',
  },
]

const FOOTER_NAVIGATION = [
  {
    name: <FormattedMessage id="footer.navigation.legal" />,
    submenu: [
      {
        name: <FormattedMessage id="footer.navigation.legal.submenu.terms" />,
        href: '/terms_of_service',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.legal.submenu.privacy" />,
        href: '/privacy_policy',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.legal.submenu.cookie" />,
        href: '/cookie_policy',
        target: '_blank',
      },
    ],
  },
  {
    name: <FormattedMessage id="footer.navigation.exchange" />,
    submenu: [
      {
        name: <FormattedMessage id="footer.navigation.exchange.submenu.support" />,
        href: 'https://docs.openware.com/opendax/user-guide/v4-quick-start',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.exchange.submenu.tokens" />,
        href: '/list_your_token',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.exchange.submenu.yellowToken" />,
        href: '/discover_yellow_token',
        target: '_blank',
      },
    ],
  },
  {
    name: <FormattedMessage id="footer.navigation.company" />,
    submenu: [
      {
        name: <FormattedMessage id="footer.navigation.company.submenu.incubator" />,
        href: 'https://www.yellowincubator.com/',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.company.submenu.coworking" />,
        href: 'https://www.yellowincubator.com/coworking',
        target: '_blank',
      },
      {
        name: <FormattedMessage id="footer.navigation.company.submenu.foundation" />,
        href: 'https://www.yellowincubator.com/foundation',
        target: '_blank',
      },
    ],
  },
]

export const FOOTER_OPTIONS = {
  navigations: FOOTER_NAVIGATION,
  socials: FOOTER_SOCIALS,
  upIcon: () => <Icons name="upIcon" />,
  downIcon: () => <Icons name="downIcon" />,
  socialIconNumDisplay: 8,
}
