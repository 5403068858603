import { Layout } from 'components'
import Image from 'next/image'
import { withRouter } from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface BoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<any, BoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  setToDefault = (): void => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidMount() {
    setInterval(() => this.setState({ hasError: false }), 100)
  }

  componentWillUnmount() {
    this.setState({ hasError: false })
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: store the error log somewhere
    console.log({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout headerOptions={{ navigations: [] }}>
          <div className="bg-body-background-color flex flex-col h-full w-full pr-6 pl-6">
            <div className="flex flex-col lg:flex-row justify-center items-center h-screen">
              <div className="w-full lg:w-1/3 h-1/2 relative">
                <Image alt="Client error" src="/images/500.svg" layout="fill" />
              </div>
              <div className="h-full flex lg:items-center">
                <div className="flex flex-col">
                  <div className="text-text-color-90 mb-10 text-6xl font-gilmer-bold w-[500px] max-w-[100vw] text-center lg:text-left">
                    <FormattedMessage id="page.400.title" />
                  </div>
                  <button
                    onClick={() => {
                      this.setState({ hasError: false })
                      this.props.router.reload()
                    }}
                    className="self-center lg:self-auto border flex justify-center items-center text-sm font-metro-semibold border-primary-cta-layer-color-60 text-primary-cta-layer-color-60 bg-primary-cta-color-60 w-[296px] h-[40px] hover:bg-primary-cta-color-80 rounded shadow-sm"
                  >
                    <FormattedMessage id="page.400.refresh" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
